<template>
  <v-container>
    <v-layout row wrap xs12 class="ma-2 justify-space-between">
      <h1 class="ma-4 d-flex">
        <span class="ahcRed">Gebruiker aanpassen</span>
      </h1>
    </v-layout>
    <v-col cols="12" sm="6">
      <v-form ref="form">
        <v-text-field v-model="user.name" label="Naam" :rules="[rules.required]"></v-text-field>
        <v-text-field
          v-model="user.email"
          :rules="[rules.required,rules.validEmail]"
          label="E-mail"
          required
        ></v-text-field>
        <v-select
          :items="['Admin','User','Editor']"
          v-model="user.role"
          :menu-props="{ top: true, offsetY: true }"
          label="Rol"
        ></v-select>
        <v-select
          v-model="user.function"
          :items="functions"
          :menu-props="{ top: true, offsetY: true }"
          label="Functie"
          multiple
          hint="Wijs geen ceremoniële functies toe voor de prinsenpresentatie!"
          persistent-hint
        ></v-select>

        <div class="text-center mb-2">
          <span class="center red--text" v-if="feedback">{{feedback}}</span>
        </div>
        <v-row justify="center" class="ma-5">
          <v-btn @click="editUser()" outlined rounded text class="ma-2">
            <span>Aanpassen</span>
            <v-icon small right>mdi-account-plus-outline</v-icon>
          </v-btn>
          <v-btn outlined rounded text class="ma-2">
            <router-link :to="{name: 'Users' }" class="black--text">
              <span>Terug</span>
              <v-icon small right>mdi-arrow-left-circle</v-icon>
            </router-link>
          </v-btn>
        </v-row>
      </v-form>
    </v-col>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      feedback: null,
      rules: {
        required: value => !!value || "Verplicht",
        validEmail: v =>
          /.+@.+\..+/.test(v) || "E-mail voldoet niet aan het juiste formaat",
        min: v => v.length >= 8 || "Min 8 karakters"
      },
      functions: [
        "Voorzitter",
        "Secretaris",
        "Vice secretaris",
        "Penningmeester",
        "Prins",
        "Adjudant",
        "Decaan",
        "Prins",
        "Pliessie"
      ]
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    users() {
      return this.$store.state.users;
    }
  },
  methods: {
    editUser() {
      if (this.user.function.length > 0) {
        for (let i = 0; i < this.user.function.length; i++) {
          for (let j = 0; j < this.users.length; j++) {
            if (this.users[j].id != this.user.id) {
              if (this.users[j].function.length > 0) {
                console.log("user.function: " + this.user.function[i]);
                console.log("users.function 1: " + this.users[j].function);

                let index = this.users[j].function.indexOf(
                  this.user.function[i]
                );
                console.log("index: " + index);
                if (typeof index == "number") {
                  console.log("inside splice");
                  this.users[j].function.splice(index, 1);
                  this.$store.dispatch("editUser", this.users[j]);
                }

                console.log("users.function 2: " + this.users[j].function);
              }
            }
          }
        }
      }
      this.$store.dispatch("editUser", this.user);
    }
  },
  checkFunction(fs, f) {
    return fs != f;
  },
  created() {
    this.$store.dispatch("getUser", this.$route.params.uid);
  }
};
</script>

<style>
</style>