<template>
  <v-container>
      <h1 class="ahcRed ma-4">Mijn advertenties</h1>
      <TotalenAdvertenties v-if="!loadingScreen && activeEdition.saleSeason" :advertisers="myAdvertisers" :advertisements="activeAdvertisements"/>
      <AdverteerderOverview v-if="!loadingScreen && activeEdition.saleSeason"
        :personalOverView="true"
        :advertisers="myAdvertisers"
        :activeEdition="activeEdition"/>
        <v-card class="ma-5" elevation="0" v-if="loadingScreen">
          <v-row class="d-flex justify-space-around mb-6">
            <v-progress-circular
              class="ma-5 ahcRed"
              :size="70"
              :width="7"
              indeterminate
            ></v-progress-circular>
          </v-row>
          <v-row class="d-flex justify-space-around mb-6">
            <h3>Inladen adverteerders</h3>
          </v-row>
        </v-card>
  </v-container>

</template>

<script>
import AdverteerderOverview from "./AdverteerderOverview.vue";
import TotalenAdvertenties from "./TotalenAdvertenties.vue";

export default {
  components: {
    AdverteerderOverview,
    TotalenAdvertenties
},
data(){
  return{
    loadingScreen: true,
  }
},
  computed:{
    myAdvertisers(){
      let advertisers = this.$store.state.paperStore.advertisers;
      let myAdvertisers = [];
      advertisers.forEach(advertiser => {
        if(advertiser.active && advertiser.contact == this.userName){
          myAdvertisers.push(advertiser);
        }
      });
      return myAdvertisers;
    },
    activeEdition(){
      let activeEditionId = this.$store.state.paperStore.activeEditionId;
      let editions = this.$store.state.paperStore.paperEditions;
      return editions.find(edition => edition.id == activeEditionId);
    },
    userName() {
      return this.$store.state.currentUser.name;
    },
    activeAdvertisements(){
      return this.$store.state.paperStore.activeAdvertisements;
    }
  },
  methods:{
    devFunctie(){
      console.log(this.activeEdition);
    }
  },
  beforeCreate() {
    this.loadingScreen = true;
    this.$store.commit("paperStore/loadAdvertisers");
    this.$store.commit("paperStore/loadPaperEditions");
    setTimeout(() => {
      this.loadingScreen = false;
    }, 2000);
  },
  }

</script>

<style>
</style>