<template>
  <div>
    <div>
      <h1 class="subheading grey--text">Gebruikers</h1>

      <v-btn class="my-5" router to="/gebruikertoevoegen">
        <v-icon>mdi-account-plus-outline</v-icon>
      </v-btn>
    </div>
    <v-container class="my-5">
      <template>
        <v-card v-for="user in users" :key="user.id" class="mx-auto ma-4" max-width="600">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title>{{user.name}}</v-list-item-title>
              <v-list-item-subtitle>{{user.email}}</v-list-item-subtitle>
              <v-list-item-subtitle>{{user.role}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions>
            <v-row justify="center">
              <v-btn outlined rounded text class="ma-2">
                <router-link :to="{name: 'EditUser', params:{uid: user.id}}" class="black--text">
                  <span>Aanpassen</span>
                  <v-icon small right>mdi-account-edit-outline</v-icon>
                </router-link>
              </v-btn>
              <v-btn @click="removeUser(user.id)" outlined rounded text class="ma-2">
                <span>Verwijderen</span>
                <v-icon small right>mdi-account-remove-outline</v-icon>
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
        <v-divider></v-divider>
      </template>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      deleteDialog: false
    };
  },
  computed: {
    users() {
      return this.$store.state.users;
    },
    currentUser() {
      return this.$store.state.currentUser;
    }
  },
  methods: {
    removeUser(payload) {
      this.$store.dispatch("removeUser", payload);
    }
  },
  created() {
    this.$store.dispatch("loadUsers");
  }
};
</script>

<style>
.v-expansion-panel-header {
  width: 0%;
}
</style>