<template>
  <div>
    <h1 class="ma-4 d-flex">
      <span class="ahcRed">Gebruiker toevoegen</span>
    </h1>
    <v-container class="grey--text">
      <v-col cols="12" sm="6">
        <v-form ref="form" align="center">
          <v-text-field
            v-model="name"
            label="Naam"
            :rules="[rules.required]"
          ></v-text-field>
          <v-text-field
            v-model="email"
            :rules="[rules.required, rules.validEmail]"
            label="E-mail"
            required
          ></v-text-field>
          <v-select
            :items="['Admin', 'User', 'Editor']"
            v-model="role"
            :menu-props="{ top: true, offsetY: true }"
            label="Rol"
          ></v-select>
          <v-text-field
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show ? 'text' : 'password'"
            label="Wachtwoord"
            @click:append="show = !show"
            v-model="password"
            required
          ></v-text-field>
          <div class="text-center mb-2">
            <span class="center red--text" v-if="feedback">{{ feedback }}</span>
          </div>
          <v-row justify="center">
            <v-btn @click="addUser" outlined rounded text class="ma-2">
              <span>Toevoegen</span>
              <v-icon small right>mdi-account-plus-outline</v-icon>
            </v-btn>
            <v-btn outlined rounded text class="ma-2">
              <router-link :to="{ name: 'Users' }" class="black--text">
                <span>Terug</span>
                <v-icon small right>mdi-arrow-left-circle</v-icon>
              </router-link>
            </v-btn>
          </v-row>
        </v-form>
      </v-col>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: null,
      email: null,
      password: "",
      show: false,
      feedback: null,
      role: null,
      function: [],
      rules: {
        required: (value) => !!value || "Verplicht",
        validEmail: (v) =>
          /.+@.+\..+/.test(v) || "E-mail voldoet niet aan het juiste formaat",
        min: (v) => v.length >= 8 || "Min 8 karakters",
      },
    };
  },
  methods: {
    addUser() {
      if (this.name && this.email && this.password) {
        let user = {
          password: this.password,
          name: this.name,
          email: this.email,
          role: this.role,
          memberOf: [],
          function: [],
        };
        this.$store.dispatch("addUser", user).catch((error) => {
          this.feedback = error.message;
        });
      } else {
        this.feedback = "Alle velden moeten gevuld worden";
      }
    },
  },
};
</script>

<style>
</style>