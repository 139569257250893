<template>
  <v-container>
    <h3>Financien</h3>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>