<template>
  <v-container>
    <v-card>
      <v-system-bar class="pa-4" color="#ebb63b">
        <span class="ahcRed subheading">
          <b>Editie overzicht</b>
        </span>
        <!-- <v-spacer></v-spacer>
        <v-chip dark color="#ebb63b" >
        <v-select color="#cc212f" label="Editie" :items="editionOptions" v-model="selectedEditionId"></v-select>
      </v-chip > -->
        <!-- <div class="d-flex" v-if="!addEditionDialog">
          <v-icon color="#cc212f" @click="addEditionDialog = true"
            >mdi-plus-outline</v-icon
          >
        </div> -->
      </v-system-bar>
      <v-card-text>
        <v-row >
        <TotalenAdvertenties class="ma-3" :advertisers="advertisers" :advertisements="activeAdvertisements"/>
      </v-row>
      <v-row class="justify-center">
        <v-radio-group
        class="ma-3"
      v-model="view"
      row 
      color="#ebb63b"
    >
    <v-radio
        label="Totalen per verkoper"
        value="0"
      ></v-radio>
      <v-radio
        label="Te beoordelen advertenties"
        value="1"
      ></v-radio>
      <v-radio
        label="Open advertenties"
        value="2"
      ></v-radio>
      <v-radio
        label="Niet verkocht"
        value="3"
      ></v-radio>
    </v-radio-group>
    
      </v-row>
      <v-row class="justify-center">
        <TotalenPerUser class="ma-3" v-if="view == '0'"/>
        <AdvertentieOverview class="ma-3" v-if="view == '1'" :redactionPage="'initial'"/>
        <AdvertentieOverview class="ma-3" v-if="view == '2'" :redactionPage="'open'"/>
        <AdvertentieOverview class="ma-3" v-if="view == '3'" :redactionPage="'notSold'"/>
      </v-row>
      </v-card-text>
   
    </v-card>
    <v-dialog
      transition="dialog-top-transition"
      max-width="300"
      v-model="addEditionDialog"
    >
      <v-card v-if="!buzzy">
        <v-card-title class="headerClass">Nieuwe editie openen</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newEdition.title"
            label="Titel"
            placeholder="bv: 2025"
          ></v-text-field>
          <v-switch
            v-model="newEdition.active"
            label="Is dit de actieve editie?"
          >
          </v-switch>
          <v-switch
            v-model="newEdition.saleSeason"
            label="Is het verkoop seizoen open?"
          >
          </v-switch>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="(addEditionDialog = false), (newEdition = {active: false, saleSeason: false})"
            >Annuleren</v-btn
          >

          <v-btn
            color="green darken-1"
            text
            v-if="newEdition.title"
            @click="addEdition()"
            >Creëren</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="ma-5" elevation="0" v-if="buzzy">
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular
            class="ma-5 ahcRed"
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Nieuwe editie creëren</h3>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import AdvertentieOverview from './AdvertentieOverview.vue';
import TotalenAdvertenties from './TotalenAdvertenties.vue';
import TotalenPerUser from './TotalenPerUser.vue';

export default {
  name: "EditiesOverview",
    data() {
        return {
            addEditionDialog: false,
            newEdition: { active: false, saleSeason: false },
            selectedEditionId: null,
            selectedEdition: null,
            buzzy: false,
            view: "0",
        };
    },
    props: {
        editions: {
            type: Array,
            required: true,
        },
        advertisers: {
            type: Array,
            required: true,
        },
    },
    computed: {
        userName() {
            return this.$store.state.currentUser.name;
        },
        editionOptions() {
            return this.editions.map((edition) => {
                return {
                    text: edition.title,
                    value: edition.id,
                };
            });
        },
        activeEditionId() {
            return this.$store.state.paperStore.activeEditionId;
        },
        activeAdvertisements(){
      return this.$store.state.paperStore.activeAdvertisements;
    }
    },
    methods: {
        addEdition() {
            this.buzzy = true;
            this.newEdition.id = Date.now();
            this.newEdition.changeLog = [];
            let createdMsg = `Editie is aangemaakt door ${this.userName} op ${new Date().toLocaleDateString()}`;
            this.newEdition.changeLog.push(createdMsg);
            this.$store
                .commit("paperStore/addPaperEdition", { newEdition: this.newEdition });
            setTimeout(() => {
                this.buzzy = false;
                this.addEditionDialog = false;
                this.newEdition = { active: false, saleSeason: false };
            }, 1000);
        },
        switchActiveEdition(editionId) {
            this.$store
                .commit("paperStore/switchActiveEdition", { id: editionId })
                .catch((err) => {
                console.log(err);
            });
        },
    },
    created() {
        setTimeout(() => {
            this.selectedEditionId = this.activeEditionId;
        }, 1000);
    },
    watch: {
        selectedEditionId() {
            this.selectedEdition = this.editions.find((edition) => edition.id == this.selectedEditionId);
        },
    },
    components: { TotalenAdvertenties, TotalenPerUser, AdvertentieOverview }
};
</script>

<style>
</style>