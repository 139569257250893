<template>
  <v-container>
    <v-layout row wrap xs12 class="ma-2 justify-space-between">
      <h1>
        <span class="ahcRed">Dwaile</span>
      </h1>
    </v-layout>
    <v-card name="introWebsite">
      <v-system-bar class="pa-4" color="#ebb63b">
        <span>
          <b>Introductie tekst Dwaile</b>
        </span>
      </v-system-bar>
      <v-textarea
        class="pa-2"
        v-model="dwaileIntro"
        disabled
        v-if="lockIntro"
      ></v-textarea>
      <v-textarea
        class="pa-2"
        v-model="newIntro"
        v-if="!lockIntro"
      ></v-textarea>
      <v-card-actions>
        <v-btn @click="changeIntro()" v-if="lockIntro">Aanpassen</v-btn>
        <v-btn v-if="!lockIntro" @click="updateIntro()">Opslaan</v-btn>
        <v-btn v-if="!lockIntro" @click="lockIntro = true">Annuleren</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      lockIntro: true,
      newIntro: null,
    };
  },
  computed: {
    userName() {
      return this.$store.state.currentUser.name;
    },
    dwaileIntro() {
      return this.$store.state.intros.dwaile;
    },
  },
  methods: {
    changeIntro() {
      this.lockIntro = false;
      this.newIntro = this.dwaileIntro;
    },
    updateIntro() {
      let intro = {
        name: "dwaile",
        text: this.newIntro,
      };
      this.$store.dispatch("updateIntros", intro);
      setTimeout(() => {
        this.lockIntro = true;
      }, 2000);
    },
  },
};
</script>

<style>
</style>