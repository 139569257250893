<template>
  <v-container>
    <v-layout row wrap xs12 class="ma-2 justify-space-between">
      <h1>
        <span class="ahcRed">Instellingen webshop</span>
      </h1>
    </v-layout>
    <v-card name="stockLocations">
      <v-system-bar class="pa-4" color="#ebb63b">
        <span class="ahcRed subheading"><b>Voorraad locaties</b></span>
        <v-spacer></v-spacer>
        <div v-if="this.$store.state.webshop !== null">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="#cc212f"
                v-bind="attrs"
                v-on="on"
                @click="newStockForm = true"
                >mdi-plus-outline</v-icon
              >
            </template>
            <span>Locatie toevoegen.</span>
          </v-tooltip>
        </div>
      </v-system-bar>
      <v-alert outlined color="#ebb63b">
        <v-row class="ma-2">
          <v-card
            class="ml-2"
            v-for="(loc, i) in stock"
            :key="i"
            max-width="300"
          >
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>{{ loc.title }}</b>
              </span>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="!loc.locked"
                    @click="deleteStockpile(loc.id)"
                    v-bind="attrs"
                    v-on="on"
                    color="#cc212f"
                    >mdi-delete-outline</v-icon
                  >
                </template>
                <span
                  >Locatie verwijderen. Alle voorraad van deze locatie wordt
                  terug toegevoegd aan de algemene voorraad.</span
                >
              </v-tooltip>
            </v-system-bar>
            <v-card-text class="d-flex flex-column">
              <span>
                <small> <b>Beschrijving:</b> {{ loc.description }} </small>
              </span>
              <span>
                <small>
                  <b>Intern/extern: </b>{{ getInternalStatus(loc.id) }}
                </small>
              </span>
              <span>
                <small> <b>Webshop?:</b> {{ getWebshopStatus(loc.id) }} </small>
              </span>
            </v-card-text>
          </v-card>
        </v-row>
      </v-alert>
    </v-card>
    <v-card name="introWebsite">
      <v-system-bar class="pa-4" color="#ebb63b">
        <span>
          <b>Introductie tekst Webshop</b>
        </span>
      </v-system-bar>
      <v-textarea
        class="pa-2"
        v-model="webshopIntro"
        disabled
        v-if="lockIntro"
      ></v-textarea>
      <v-textarea
        class="pa-2"
        v-model="newIntro"
        v-if="!lockIntro"
      ></v-textarea>
      <v-card-actions>
        <v-btn @click="changeIntro()" v-if="lockIntro">Aanpassen</v-btn>
        <v-btn v-if="!lockIntro" @click="updateIntro()">Opslaan</v-btn>
        <v-btn v-if="!lockIntro" @click="lockIntro = true">Annuleren</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="newStockForm"
      transition="dialog-top-transition"
      max-width="600"
    >
      <v-card>
        <v-system-bar class="pa-4" color="#ebb63b">
          <span class="ahcRed subheading">
            <b>Nieuwe voorraad locatie toevoegen</b>
          </span>
        </v-system-bar>
        <v-form class="ma-3">
          <v-text-field
            v-model="newStock.title"
            label="Naam (verplicht)"
            placeholder="bv Loods.."
          ></v-text-field>
          <v-textarea
            v-model="newStock.description"
            label="Beschrijving"
          ></v-textarea>
          <v-radio-group v-model="newStock.internal" label="Intern of extern?">
            <v-radio label="Intern" :value="true"></v-radio>
            <v-radio label="Extern" :value="false"></v-radio>
          </v-radio-group>
          <v-radio-group
            v-if="newStock.internal"
            v-model="newStock.forWebshop"
            label="Voorraad beschikbaar voor webshop?"
          >
            <v-radio label="Beschikbaar" :value="true"></v-radio>
            <v-radio label="Niet beschikbaar" :value="false"></v-radio>
          </v-radio-group>
          <v-btn class="ma-2" @click="addStockpile" v-if="newStock.title">
            Opslaan
          </v-btn>
          <v-btn
            class="ma-2"
            @click="
              (newStock = { internal: true, forWebshop: false }),
                (newStockForm = false)
            "
          >
            Annuleren
          </v-btn>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      newStock: {
        internal: true,
        forWebshop: false,
      },
      newStockForm: false,
      lockIntro: true,
      newIntro: null,
    };
  },
  computed: {
    stock() {
      let stock = [];
      if (this.$store.state.webshop !== null) {
        stock = this.$store.state.webshop.stock;
      }
      return stock;
    },
    products() {
      let products = [];
      if (this.$store.state.webshop !== null) {
        products = this.$store.state.webshop.products;
      }
      return products;
    },
    userName() {
      return this.$store.state.currentUser.name;
    },
    webshopIntro() {
      return this.$store.state.intros.webshop;
    },
  },
  methods: {
    getInternalStatus(id) {
      let status = "Intern";
      this.stock.forEach((x) => {
        if (x.id === id) {
          if (!x.internal) {
            status = "Extern";
          }
        }
      });
      return status;
    },
    getWebshopStatus(id) {
      let status = "Beschikbaar voor de webshop";
      this.stock.forEach((x) => {
        if (x.id === id) {
          if (!x.internal) {
            status = "Niet beschikbaar voor de webshop";
          }
        }
      });
      return status;
    },
    addStockpile() {
      let duplicateName = false;
      this.stock.forEach((x) => {
        if (x.title === this.newStock.title) {
          duplicateName = true;
        }
      });
      if (!duplicateName) {
        let description = "";
        if (this.newStock.description) {
          description = this.newStock.description;
        }
        let creationDate = new Date();
        let dd = String(creationDate.getDate()).padStart(2, "0");
        let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = creationDate.getFullYear();
        creationDate = dd + "/" + mm + "/" + yyyy;
        let stockpile = {
          title: this.newStock.title,
          id: Date.now(),
          locked: false,
          internal: this.newStock.internal,
          forWebshop: this.newStock.forWebshop,
          products: [],
          description: description,
          changeLog: [],
        };
        let createdMsg = `Voorraad is aangemaakt door ${this.userName} op ${creationDate}`;
        stockpile.changeLog.push(createdMsg);
        this.products.forEach((x) => {
          stockpile.products.push({ title: x.title, amount: 0, id: x.id });
        });
        this.$store.state.webshop.stock.push(stockpile);
        this.$store.dispatch("saveWebshop");
        this.newStock = {
          internal: true,
          forWebshop: false,
        };
        this.newStockForm = false;
      } else {
        alert("Deze naam voor een voorraad is al in gebruik, kies een andere");
      }
    },
    deleteStockpile(id) {
      let index;
      for (let i = 0; i < this.stock.length; i++) {
        if (this.stock[i].id === id) {
          index = i;
          this.stock[i].products.forEach((y) => {
            if (y.amount > 0) {
              this.stock.forEach((z) => {
                if (z.title === "Algemeen") {
                  z.products.forEach((a) => {
                    if (y.id === a.id) {
                      a.amount += y.amount;
                    }
                  });
                }
              });
            }
          });
        }
      }
      this.stock.splice(index, 1);
      this.$store.dispatch("saveWebshop");
    },
    changeIntro() {
      this.lockIntro = false;
      this.newIntro = this.webshopIntro;
    },
    updateIntro() {
      let intro = {
        name: "webshop",
        text: this.newIntro,
      };
      this.$store.dispatch("updateIntros", intro);
      setTimeout(() => {
        this.lockIntro = true;
      }, 2000);
    },
  },
  beforeCreate() {
    if (this.$store.state.webshop === null) {
      this.$store.dispatch("loadWebshop");
      this.$store.dispatch("loadProductImages");
    }
  },
};
</script>

<style>
</style>