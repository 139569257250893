<template>
  <v-container>
    <v-layout row wrap xs12 class="ma-2 justify-space-between">
      <h1 class="ma-4 d-flex">
        <span class="ahcRed">Club van 111</span>
      </h1>
    </v-layout>
    <v-layout row wrap class="ma-3" name="layoutMemberModules">
      <v-flex xs12 name="flexInfoPanel">
        <v-flex class="ma-2" xs12>
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Overzicht</b>
              </span>
              <v-spacer></v-spacer>
              <div class="d-flex" v-if="!addEditionDialog">
                <v-icon color="#cc212f" @click="addEditionDialog = true"
                  >mdi-plus-outline</v-icon
                >
              </div>
            </v-system-bar>
            <v-tabs color="#cc212f">
              <v-tab
                v-model="editionTab"
                v-for="(edition, i) in clubEditions.editions"
                :key="i"
              >
                {{ edition.title }}
              </v-tab>
              <v-tab-item
                v-for="(edition, i) in clubEditions.editions"
                :key="i"
              >
                <v-card-text>
                  <v-btn
                    class="my-2"
                    v-if="!edition.active"
                    @click="switchActiveEdition(edition.title)"
                    >Activeer editie</v-btn
                  >

                  <v-alert outlined color="#ebb63b">
                    <v-flex xs12 class="ma-2">
                      <v-row>
                        <v-col class="d-flex justify-center">
                          <v-card flat
                            ><v-chip color="#ebb63b" text-color="#cc212f"
                              >Aantal leden: {{ edition.members }}</v-chip
                            ></v-card
                          >
                        </v-col>
                        <v-col class="d-flex justify-center">
                          <v-card flat
                            ><v-chip color="#ebb63b" text-color="#cc212f"
                              >Contributie bedrag: €{{
                                edition.contribution
                              }},-</v-chip
                            ></v-card
                          >
                        </v-col>
                        <v-col class="d-flex justify-center">
                          <v-card flat
                            ><v-chip color="#ebb63b" text-color="#cc212f"
                              >Aantal betalingen:
                              {{
                                overviewData[edition.title].paidMembers
                              }}</v-chip
                            ></v-card
                          >
                        </v-col>
                        <v-col class="d-flex justify-center">
                          <v-card flat
                            ><v-chip color="#ebb63b" text-color="#cc212f"
                              >Opbrengst: €{{
                                overviewData[edition.title].paidMembers *
                                parseInt(edition.contribution)
                              }},-</v-chip
                            ></v-card
                          >
                        </v-col>
                      </v-row>
                      <v-row v-if="edition.active">
                        <v-col class="d-flex justify-center">
                          <v-card flat
                            ><v-chip color="#ebb63b" text-color="#cc212f"
                              >Jubilarissen 11 jaar: {{ jubilee11 }}</v-chip
                            ></v-card
                          >
                        </v-col>
                        <v-col class="d-flex justify-center">
                          <v-card flat
                            ><v-chip color="#ebb63b" text-color="#cc212f"
                              >Jubilarissen 22 jaar: {{ jubilee22 }}</v-chip
                            ></v-card
                          >
                        </v-col>
                        <v-col class="d-flex justify-center">
                          <v-card flat
                            ><v-chip color="#ebb63b" text-color="#cc212f"
                              >Jubilarissen 33 jaar: {{ jubilee33 }}</v-chip
                            ></v-card
                          >
                        </v-col>
                        <v-col class="d-flex justify-center">
                          <v-card flat
                            ><v-chip color="#ebb63b" text-color="#cc212f"
                              >Jubilarissen 44 jaar:{{ jubilee44 }}</v-chip
                            ></v-card
                          >
                        </v-col>
                      </v-row>
                    </v-flex>
                  </v-alert>
                  <span class="my-3 ahcRed subheading">
                    <b>Contactmomenten:</b>
                  </span>
                  <v-row class="ma-2">
                    <v-card
                      class="ml-2"
                      v-for="(moment, j) in edition.contactMoments"
                      :key="j"
                      max-width="300"
                    >
                      <v-system-bar class="pa-4" color="#ebb63b">
                        <span class="ahcRed subheading">
                          <b>{{ moment.title }}</b>
                        </span>
                      </v-system-bar>
                      <v-card-text class="d-flex flex-column">
                        <span
                          ><small
                            >Aangemaakt door: <b>{{ moment.creator }}</b></small
                          ></span
                        >
                        <span
                          ><small
                            >Aangemaakt op:
                            <b>{{ moment.creationDate }}</b></small
                          ></span
                        >
                        <span
                          ><small
                            >Betalingslink:
                            <b>{{ moment.payment ? "ja" : "nee" }}</b></small
                          ></span
                        >
                        <span
                          ><small
                            >Voor wie:
                            <b>{{
                              moment.payment
                                ? moment.allMembers
                                  ? "Alle leden"
                                  : "Alleen wanbetalers"
                                : "nvt"
                            }}</b></small
                          ></span
                        >
                        <span
                          ><small
                            >Buutkaarten:
                            <b>{{
                              moment.payment
                                ? moment.buut
                                  ? "Ja"
                                  : "Nee"
                                : "nvt"
                            }}</b></small
                          ></span
                        >
                        <span
                          ><small
                            >Prijs kaarten:
                            <b>{{
                              moment.payment
                                ? moment.buut
                                  ? "€" + moment.buutPrice + ",-"
                                  : "nvt"
                                : "nvt"
                            }}</b></small
                          ></span
                        >
                        <span
                          ><small
                            >Max aantal kaarten:
                            <b>{{
                              moment.payment
                                ? moment.buut
                                  ? moment.buutMax
                                  : "nvt"
                                : "nvt"
                            }}</b></small
                          ></span
                        >
                        <span
                          ><small
                            >Deadline buutkaarten:
                            <b>{{
                              moment.payment
                                ? moment.buut
                                  ? moment.buutDeadline
                                  : "nvt"
                                : "nvt"
                            }}</b></small
                          ></span
                        >
                        <span
                          ><small
                            >Al gegenereerd:
                            <b>{{
                              moment.generatedOn ? moment.generatedOn : "nee"
                            }}</b></small
                          ></span
                        >
                        <v-alert outlined color="#ebb63b">
                          <v-row>
                            <v-col>
                              <v-tooltip bottom>
                                <template v-slot:[activator]="{ on, attrs }">
                                  <v-icon
                                    @click="inspectLetter(moment)"
                                    v-bind="attrs"
                                    v-on="on"
                                    >mdi-magnify</v-icon
                                  >
                                </template>
                                <span>Template downloaden</span>
                              </v-tooltip>
                            </v-col>
                            <v-col v-if="!moment.generatedOn">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="
                                      executeMoment(
                                        moment,
                                        edition.contribution
                                      )
                                    "
                                    >mdi-play-outline</v-icon
                                  >
                                </template>
                                <span>Brieven genereren</span>
                              </v-tooltip>
                            </v-col>
                            <v-col>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    @click="
                                      deleteContactMoment(
                                        moment.parent,
                                        moment.id
                                      )
                                    "
                                    v-bind="attrs"
                                    v-on="on"
                                    >mdi-delete-outline</v-icon
                                  >
                                </template>
                                <span>Moment verwijderen</span>
                              </v-tooltip>
                            </v-col>
                            <v-col v-if="moment.downloadOrder">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    @click="
                                      downloadLetters(
                                        moment.parent,
                                        moment.title,
                                        moment.downloadOrder
                                      )
                                    "
                                    v-bind="attrs"
                                    v-on="on"
                                    >mdi-briefcase-download-outline</v-icon
                                  >
                                </template>
                                <span>Download de brieven</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col class="d-flex justify-center">
                              <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    text
                                    v-if="moment.payment"
                                    v-bind="attrs"
                                    v-on="on"
                                    ><v-icon
                                      >mdi-text-box-check-outline</v-icon
                                    ></v-btn
                                  >
                                </template>
                                <v-list>
                                  <v-list-item
                                    @click="downloadReport(moment, 'reception')"
                                  >
                                    <v-list-item-title
                                      >Overzicht voor
                                      Prinsereceptie</v-list-item-title
                                    >
                                  </v-list-item>
                                  <v-list-item
                                    @click="downloadReport(moment, 'totalPays')"
                                  >
                                    <v-list-item-title
                                      >Overzicht buutkaarten +
                                      betaalinformatie</v-list-item-title
                                    >
                                  </v-list-item>
                                  <v-list-item
                                    @click="downloadReport(moment, 'homeVisit')"
                                  >
                                    <v-list-item-title
                                      >Overzicht voor
                                      huisbezoeken</v-list-item-title
                                    >
                                  </v-list-item>
                                  <v-list-item
                                    @click="checkContactMomentPayments(moment.id,)"
                                  >
                                    <v-list-item-title
                                      >Controleer mollie status van de betalingen</v-list-item-title
                                    >
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </v-alert>
                      </v-card-text>
                    </v-card>

                    <v-icon
                      size="50"
                      v-if="!addContactMomentDialog"
                      @click="
                        (addContactMomentDialog = true),
                          (newContactMoment.parent = edition.title)
                      "
                      color="#cc212f"
                      >mdi-plus</v-icon
                    >
                  </v-row>
                </v-card-text>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-flex>
      </v-flex>
      <v-flex xs12 md6 name="flexAddMember">
        <v-flex class="ma-2" xs12>
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Lid manueel toevoegen</b>
              </span>
            </v-system-bar>
            <div class="d-flex justify-center">
              <v-icon
                size="100"
                v-if="!addForm"
                @click="addForm = true"
                color="#cc212f"
                >mdi-plus</v-icon
              >
            </div>

            <v-form class="ma-3" v-if="addForm">
              <v-text-field
                v-model="newMember.id"
                label="Lidmaatschapnummer (alleen wanneer iemand al een nummer toegewezen heeft gekregen)"
              ></v-text-field>
              <v-radio-group v-model="newMember.gender" label="Geslacht">
                <v-radio label="Man" value="m"></v-radio>
                <v-radio label="Vrouw" value="f"></v-radio>
              </v-radio-group>
              <v-text-field
                v-model="newMember.firstName"
                label="Voornaam"
              ></v-text-field>
              <v-text-field
                v-model="newMember.lastName"
                label="Achternaam"
                required
              ></v-text-field>
              <v-text-field
                v-model="newMember.adres"
                label="Adres"
                placeholder="Grote Markt 1"
                required
              ></v-text-field>
              <v-text-field
                v-model="newMember.postalCode"
                label="Postcode"
                placeholder="4561AA"
                required
              ></v-text-field>
              <v-text-field
                v-model="newMember.city"
                label="Stad/dorp"
                placeholder="Hulst"
                required
              ></v-text-field>
              <v-select
                label="Land"
                v-model="newMember.country"
                :items="countries"
                required
              ></v-select>
              <v-text-field
                v-model="newMember.email"
                label="Email adres"
                placeholder="pietje@precies.nl"
              ></v-text-field>
              <v-text-field
                v-model="newMember.phone"
                label="Telefoonnummer"
                placeholder="0612345678"
              ></v-text-field>
              <v-menu
                ref="menu"
                v-model="bdayMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="newMember.bday"
                    label="Geboortedatum"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="#ebb63b"
                  header-color="#ebb63b"
                  v-model="newMember.bday"
                  :active-picker.sync="activeBdayPicker"
                  :max="
                    new Date(
                      Date.now() - new Date().getTimezoneOffset() * 60000
                    )
                      .toISOString()
                      .substr(0, 10)
                  "
                  min="1930-01-01"
                  @change="save"
                ></v-date-picker>
              </v-menu>
              <v-select
                v-model="newMember.paidYears"
                :items="years"
                label="Selecteer ALLE jaren waarin het lid betaald heeft"
                multiple
                chips
                required
              ></v-select>
              <v-textarea
                label="To Do's"
                v-model="newMember.todo"
                hint="Bv: 'Nog een lint geven'"
                persistent-hint
              >
              </v-textarea>
              <v-textarea label="Notities" v-model="newMember.notes">
              </v-textarea>
              <v-btn
                class="ma-2"
                @click="saveNewMember"
                v-if="
                  newMember.lastName &&
                  newMember.adres &&
                  newMember.postalCode &&
                  newMember.city &&
                  newMember.country
                "
              >
                Opslaan
              </v-btn>
              <v-btn class="ma-2" @click="(newMember = {}), (addForm = false)">
                Annuleren
              </v-btn>
            </v-form>
          </v-card>
        </v-flex>
      </v-flex>
      <v-flex xs12 md6 name="flexAddPayment" v-if="clubMembers">
        <v-flex class="ma-2" xs12>
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Betaling verwerken</b>
              </span>
            </v-system-bar>
            <div class="d-flex justify-center">
              <v-icon
                size="100"
                v-if="!addPayment"
                @click="addPayment = true"
                color="#cc212f"
                >mdi-plus</v-icon
              >
            </div>
            <v-form v-if="addPayment" class="ma-2">
              <v-select :items="paymentMembers" chips v-model="payment.member">
              </v-select>
              <v-select :items="years" chips v-model="payment.year"></v-select>
              <v-btn
                class="ma-2"
                @click="savePayment"
                v-if="payment.member && payment.year"
              >
                Opslaan
              </v-btn>
              <v-btn class="ma-2" @click="(payment = {}), (addPayment = false)">
                Annuleren
              </v-btn>
            </v-form>
          </v-card>
        </v-flex>
      </v-flex>
      <v-flex
        xs12
        name="flexMembersOverview"
        v-if="clubMembers && clubEditions"
      >
        <v-flex class="ma-2" xs12>
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Overzicht leden Club van 111</b>
              </span>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="downloadReport(null, 'backup')"
                    color="#cc212f"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-download</v-icon
                  >
                </template>
                <span>Backup bestand met alle leden informatie.</span>
              </v-tooltip>
            </v-system-bar>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Zoeken"
              class="ma-5"
              single-line
              hide-details
            ></v-text-field>
            <v-data-table
              :headers="memberHeaders"
              :items="clubMembers"
              :single-expand="singleExpand"
              :expanded.sync="expanded"
              :search="search"
              item-key="id"
              show-expand
              hide-default-footer
              loading-text="De ledenlijst wordt ingeladen"
              disable-pagination
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-alert outlined color="#ebb63b">
                    <v-card v-if="!editMemberDiv" flat>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <span
                            >Voornaam: <b>{{ item.firstName }}</b></span
                          >
                        </v-col>
                        <v-col>
                          <span
                            >Achternaam: <b>{{ item.lastName }}</b></span
                          >
                        </v-col>
                        <v-col class="d-flex justify-end">
                          <v-icon
                            @click="(editMemberDiv = true), (editMember = item)"
                            >mdi-cog</v-icon
                          >
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <span
                            >Adres: <b>{{ item.adres }}</b></span
                          >
                        </v-col>
                        <v-col>
                          <span
                            >Postcode: <b>{{ item.postalCode }}</b></span
                          >
                        </v-col>
                        <v-col>
                          <span></span>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <span
                            >Plaats: <b>{{ item.city }}</b></span
                          >
                        </v-col>
                        <v-col>
                          <span
                            >Land: <b>{{ item.country }}</b></span
                          >
                        </v-col>
                        <v-col>
                          <span></span>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <span
                            >Telefoonnummer: <b>{{ item.phone }}</b></span
                          >
                        </v-col>
                        <v-col>
                          <span
                            >Emailadres: <b>{{ item.email }}</b></span
                          >
                        </v-col>
                        <v-col>
                          <span>
                            Geboortedatum: <b>{{ item.bday }}</b>
                          </span>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <span
                            >Lid sinds:
                            <b>{{ parseInt(item.id.slice(0, 4)) }}</b></span
                          >
                        </v-col>
                        <v-col>
                          <span
                            >Aantal jaren betaald:
                            <b>{{ item.paidYears.length }}</b></span
                          >
                        </v-col>
                        <v-col>
                          <span
                            >Jubileum dit jaar:
                            <b>{{
                              item.jubilee
                                ? "Ja (" + item.jubileeCount + " jaar)"
                                : " Nee"
                            }}</b></span
                          >
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <h3>Todo's:</h3>
                          <span>{{ item.todo }}</span>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <h3>Notities:</h3>
                          <span>{{ item.notes }}</span>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <h3>Logboek</h3>
                          <span v-for="(log, l) in item.changeLog" :key="l"
                            ><i>{{ log }}</i
                            ><br
                          /></span>
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-card v-if="editMemberDiv" flat>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <v-text-field
                            v-model="editMember.firstName"
                            label="Voornaam"
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field
                            v-model="editMember.lastName"
                            label="Achternaam"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <v-text-field
                            v-model="editMember.adres"
                            label="Adres"
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field
                            v-model="editMember.postalCode"
                            label="Postcode"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <v-text-field
                            v-model="editMember.city"
                            label="Plaats"
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-select
                            v-model="editMember.country"
                            label="Land"
                            :items="countries"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <v-text-field
                            v-model="editMember.phone"
                            label="Telefoonnummer"
                          ></v-text-field>
                        </v-col>
                        <v-col>
                          <v-text-field
                            v-model="editMember.email"
                            label="Emailadres"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <v-menu
                            ref="menu"
                            v-model="bdayMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editMember.bday"
                                label="Geboortedatum"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              color="#ebb63b"
                              header-color="#ebb63b"
                              v-model="editMember.bday"
                              :active-picker.sync="activeBdayPicker"
                              :max="
                                new Date(
                                  Date.now() -
                                    new Date().getTimezoneOffset() * 60000
                                )
                                  .toISOString()
                                  .substr(0, 10)
                              "
                              min="1930-01-01"
                              @change="save"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col>
                          <v-select
                            v-model="editMember.paidYears"
                            :items="years"
                            label="Betaalde jaren"
                            multiple
                            chips
                          ></v-select>
                        </v-col>
                        <v-col>
                          <v-radio-group
                            v-model="editMember.active"
                            label="Actief"
                          >
                            <v-radio label="Ja" v-bind:value="true"></v-radio>
                            <v-radio label="Nee" v-bind:value="false"></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <v-textarea
                            v-model="editMember.todo"
                            label="Todo"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-around ma-3">
                        <v-col>
                          <v-textarea
                            v-model="editMember.notes"
                            label="Notities"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                      <v-row class="d-flex justify-center ma-3">
                        <v-btn
                          @click="(editMemberDiv = false), (editmember = {})"
                          >Annuleren</v-btn
                        >
                        <v-btn @click="saveMember">Opslaan</v-btn>
                      </v-row>
                    </v-card>
                  </v-alert>
                </td>
              </template>
              <template v-slot:[`item.active`]="{ item }">
                <v-icon :color="getColor(item.active)" dark>
                  mdi-circle
                </v-icon>
              </template>
              <template v-slot:[`item.todo`]="{ item }">
                <v-icon v-if="'todo' in item"> mdi-exclamation-thick </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-dialog
      transition="dialog-top-transition"
      max-width="300"
      v-model="addEditionDialog"
    >
      <v-card v-if="!buzzy">
        <v-card-title class="headerClass">Nieuwe editie starten</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newEdition.title"
            label="Titel"
            placeholder="bv: 2022"
          ></v-text-field>
          <v-text-field
            v-model="newEdition.contribution"
            label="Contributie bedrag"
            placeholder="bv: 66"
          ></v-text-field>

          <v-switch
            v-model="newEdition.active"
            label="Is dit de actieve editie?"
          >
          </v-switch>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="(addEditionDialog = false), (newEdition = {})"
            >Annuleren</v-btn
          >

          <v-btn
            color="green darken-1"
            text
            v-if="newEdition.title && newEdition.contribution"
            @click="addEdition()"
            >Creëren</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="ma-5" elevation="0" v-if="buzzy">
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular
            class="ma-5 ahcRed"
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Nieuwe editie creëren</h3>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-top-transition"
      max-width="750"
      v-model="addContactMomentDialog"
    >
      <v-card v-if="!buzzy && !addContactMomentLetter">
        <v-card-title class="headerClass"
          >Nieuwe contact moment aanmaken</v-card-title
        >
        <v-card-text>
          <v-text-field
            v-model="newContactMoment.title"
            label="Titel"
            placeholder="bv: uitnodiging receptie"
          ></v-text-field>
          <v-switch
            v-model="newContactMoment.payment"
            label="Wordt er om betaling van contributie gevraagd?"
          >
          </v-switch>
          <v-switch
            v-if="newContactMoment.payment"
            v-model="newContactMoment.buut"
            label="Worden er buutkaarten aangeboden?"
          >
          </v-switch>
          <v-select
            v-if="newContactMoment.buut"
            v-model="newContactMoment.buutMax"
            label="Hoeveel kaarten max?"
            :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
          >
          </v-select>
          <v-text-field
            v-if="newContactMoment.buut"
            v-model="newContactMoment.buutPrice"
            label="Prijs van de kaarten?"
            type="number"
          >
          </v-text-field>
          <v-menu
            v-if="newContactMoment.buut"
            ref="menu"
            v-model="bdayMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="newContactMoment.buutDeadline"
                label="Deadline voor de buutkaarten"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              color="#ebb63b"
              header-color="#ebb63b"
              v-model="newContactMoment.buutDeadline"
              :active-picker.sync="activeBdayPicker"
              min="2021-01-01"
              @change="save"
            ></v-date-picker>
          </v-menu>
          <v-radio-group
            v-model="newContactMoment.allMembers"
            label="Voor wie is het moment"
            v-if="newContactMoment.payment"
          >
            <v-radio label="Voor alle leden" value="true"></v-radio>
            <v-radio label="Alleen voor wanbetalers" value="false"></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="
              (addContactMomentDialog = false),
                (newContactMoment = {}),
                (addContactMomentLetter = false)
            "
            >Annuleren</v-btn
          >

          <v-btn
            color="green darken-1"
            text
            v-if="newContactMoment.title"
            @click="addContactMomentLetter = true"
            >Volgende</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card v-if="!buzzy && addContactMomentLetter">
        <v-card-title class="headerClass">Concept nieuwe brief</v-card-title>
        <v-card-text>
          <span
            >Dit concept kan je (op dit moment) niet op een later stadium
            wijzigen.<br />Klik op de plus om paragraven toe te voegen. De datum
            en aanhef worden al gegenereerd.</span
          >
          <div
            v-for="para in newContactMomentLetter.paragraphs"
            :key="para.index"
          >
            <span
              ><small>Paragraaf {{ para.index }}</small></span
            >
            <v-radio-group
              v-if="newContactMoment.payment"
              v-model="para.type"
              label="Is het text of de betalingsmodule (max 1)"
            >
              <v-radio label="Text" value="text"></v-radio>
              <v-radio label="Betalingsmodule" value="paymentModule"></v-radio>
            </v-radio-group>
            <v-textarea
              v-if="para.type == 'text'"
              v-model="para.text"
            ></v-textarea>
          </div>
          <v-icon @click="addParagraph()" color="#cc212f">mdi-plus</v-icon>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="
              (addContactMomentDialog = false),
                (newContactMoment = {}),
                (newContactMomentLetter = {}),
                (addContactMomentLetter = false)
            "
            >Annuleren</v-btn
          >

          <v-btn color="green darken-1" text @click="addContactMoment()"
            >Moment toevoegen</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="ma-5" elevation="0" v-if="buzzy">
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular
            class="ma-5 ahcRed"
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Nieuwe contact moment creëren</h3>
        </v-row>
      </v-card>
    </v-dialog>
    <v-overlay :value="generateOverlay" opacity="0.8">
      <span
        >De brieven worden gegenereerd. Dit kan tot enkele minuten duren.</span
      >
      <br />
      <v-progress-circular
        indeterminate
        :size="70"
        :width="7"
        color="#cc212f"
        class="mx-auto"
      ></v-progress-circular>
    </v-overlay>
    <v-overlay :value="downloadOverlay" opacity="0.8">
      <span>{{ processedFile }}</span>
      <br />
      <v-progress-circular
        indeterminate
        :size="70"
        :width="7"
        color="#cc212f"
        class="mx-auto"
      ></v-progress-circular>
    </v-overlay>
    <v-overlay :value="createReportOverlay" opacity="0.8">
      <span>Rapport downloaden...</span>
      <br />
      <v-progress-circular
        indeterminate
        :size="70"
        :width="7"
        color="#cc212f"
        class="mx-auto"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import firebase from "firebase";
import Axios from "axios";
import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  Header,
  ImageRun,
  AlignmentType,
} from "docx";
import { saveAs } from "file-saver";
import { Buffer } from "buffer";
import JSZip from "jszip";
export default {
  data() {
    return {
      activeBdayPicker: null,
      newMember: {},
      addForm: false,
      addPayment: false,
      payment: {},
      countries: ["Nederland", "België"],
      bdayMenu: false,
      editMemberDiv: false,
      editMember: {},
      addEditionDialog: false,
      newEdition: {},
      buzzy: false,
      editionTab: 0,
      addContactMomentDialog: false,
      addContactMomentLetter: false,
      paragraphIndex: 1,
      newContactMoment: {},
      newContactMomentLetter: {
        paragraphs: [],
      },
      memberHeaders: [
        {
          text: "Lidmaatschapsnummer",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Voornaam", value: "firstName" },
        { text: "Achternaam", value: "lastName" },
        { text: "Actief", value: "active" },
        { text: "Jubilaris", value: "jubileeCount" },
        { text: "Todo's", value: "todo" },
        { text: "", value: "data-table-expand" },
      ],
      expanded: [],
      singleExpand: true,
      search: "",
      generateOverlay: false,
      downloadOverlay: false,
      createReportOverlay: false,
      processedFile: "",
    };
  },
  computed: {
    clubMembers() {
      return this.$store.state.clubMembers;
    },
    jubilee11() {
      return this.$store.state.jubilee11;
    },
    jubilee22() {
      return this.$store.state.jubilee22;
    },
    jubilee33() {
      return this.$store.state.jubilee33;
    },
    jubilee44() {
      return this.$store.state.jubilee44;
    },
    jubilee55() {
      return this.$store.state.jubilee55;
    },
    years() {
      let n = 1968;
      let years = [];
      for (let i = 0; i < 66; i++) {
        n++;
        years.push(n);
      }
      return years;
    },
    userName() {
      return this.$store.state.currentUser.name;
    },
    paymentMembers() {
      let ar = [];
      this.clubMembers.forEach((x) => {
        let str = `${x.id} - ${x.firstName} ${x.lastName}`;
        ar.push(str);
      });
      return ar;
    },
    clubEditions() {
      let editions = this.$store.state.clubEditions;
      if (editions.editions) {
        editions.editions.sort((a, b) => {
          return b.title - a.title;
        });
      }
      return editions;
    },
    overviewData() {
      let overviewData = [];
      for (let i = 0; i < this.clubEditions.editions.length; i++) {
        let paidMembers = 0;
        if (this.clubMembers) {
          this.clubMembers.forEach((x) => {
            if (
              x.paidYears.includes(
                parseInt(this.clubEditions.editions[i].title)
              )
            ) {
              paidMembers++;
            }
          });
        }
        overviewData[this.clubEditions.editions[i].title] = {
          paidMembers: paidMembers,
        };
      }
      return overviewData;
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activeBdayPicker = "YEAR"));
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    saveNewMember() {
      this.newMember.changeLog = [];
      let creationDate = new Date();
      let dd = String(creationDate.getDate()).padStart(2, "0");
      let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = creationDate.getFullYear();
      creationDate = dd + "/" + mm + "/" + yyyy;
      let createdMsg = `Lid is aangemaakt door ${this.userName} op ${creationDate}`;
      this.newMember.changeLog.push(createdMsg);
      if (!this.newMember.firstName) {
        this.newMember.firstName = "";
      }
      if (!this.newMember.paidYears) {
        this.newMember.paidYears = [];
      }
      this.$store.dispatch("addClubMember", { newMember: this.newMember });
      this.newMember = {};
      this.addForm = false;
    },
    savePayment() {
      let allreadyPaid = false;
      let targetMember = null;
      this.clubMembers.forEach((x) => {
        if (x.id === this.payment.member.slice(0, 6)) {
          targetMember = x;
        }
      });
      targetMember.paidYears.forEach((x) => {
        if (x === this.payment.year) {
          allreadyPaid = true;
        }
      });
      if (!allreadyPaid) {
        let creationDate = new Date();
        let dd = String(creationDate.getDate()).padStart(2, "0");
        let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = creationDate.getFullYear();
        creationDate = dd + "/" + mm + "/" + yyyy;
        let str = `De betaling voor ${this.payment.year} is toegevoegd door  ${this.userName} op ${creationDate}`;
        targetMember.changeLog.push(str);
        targetMember.paidYears.push(this.payment.year);
        this.$store.dispatch("saveClubMember", {
          member: targetMember,
        });
        for (let i = 0; i < this.clubMembers.length; i++) {
          if (this.clubMembers[i].id == targetMember.id) {
            this.clubMembers[i] = targetMember;
          }
        }

        this.payment = {};
        this.addPayment = false;
      } else {
        alert(
          `Het jaar dat je toevoegt was al betaald door ${this.payment.member}`
        );
        this.payment = {};
        this.addPayment = false;
      }
    },
    saveMember() {
      let creationDate = new Date();
      let dd = String(creationDate.getDate()).padStart(2, "0");
      let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = creationDate.getFullYear();
      creationDate = dd + "/" + mm + "/" + yyyy;
      let str = `De NAW gegevens zijn aangepast door ${this.userName} op ${creationDate}`;
      this.editMember.changeLog.push(str);
      this.$store.dispatch("saveClubMember", {
        member: this.editMember,
      });
      for (let i = 0; i < this.clubMembers.length; i++) {
        if (this.clubMembers[i].id == this.editMember.id) {
          this.clubMembers[i] = this.editMember;
        }
      }
      this.editMember = {};
      this.editMemberDiv = false;
    },
    addEdition() {
      this.buzzy = true;
      this.newEdition.members = this.clubMembers.length;
      if (!this.newEdition.active) {
        this.newEdition.active = false;
      }
      this.$store.dispatch("addClubEdition", {
        newEdition: this.newEdition,
      });
      this.newEdition = {};
      setTimeout(() => {
        this.addEditionDialog = false;
        this.buzzy = false;
      }, 2000);
    },
    switchActiveEdition(title) {
      this.clubEditions.editions.forEach((x) => {
        x.active = false;
        if (x.title == title) {
          x.active = true;
        }
      });
      this.$store.dispatch("saveClubEditions", {
        clubEditions: this.clubEditions,
      });
    },
    addContactMoment() {
      let creationDate = new Date();
      let dd = String(creationDate.getDate()).padStart(2, "0");
      let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = creationDate.getFullYear();
      this.newContactMoment.creationDate = dd + "/" + mm + "/" + yyyy;
      this.newContactMoment.creator = this.userName;
      this.newContactMoment.allMembers == "true"
        ? (this.newContactMoment.allMembers = true)
        : (this.newContactMoment.allMembers = false);
      this.newContactMoment.id = Date.now();
      this.newContactMoment.letter = this.newContactMomentLetter;
      this.clubEditions.editions.forEach((x) => {
        if (x.title == this.newContactMoment.parent) {
          x.contactMoments.push(this.newContactMoment);
        }
      });
      this.$store.dispatch("saveClubEditions", {
        clubEditions: this.clubEditions,
      });
      this.newContactMoment = {};
      this.newContactMomentLetter = {
        paragraphs: [],
      };
      this.paragraphIndex = 1;
      setTimeout(() => {
        this.addContactMomentDialog = false;
        this.addContactMomentLetter = false;
        this.buzzy = false;
      }, 2000);
    },
    getColor(active) {
      if (active) {
        return "green";
      } else {
        return "red";
      }
    },

    inspectLetter(moment) {
      let creationDate = new Date();
      let dd = String(creationDate.getDate()).padStart(2, "0");
      let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = creationDate.getFullYear();
      creationDate = dd + "/" + mm + "/" + yyyy;
      let logoBuffer = Buffer.from("../assets/logo_ahc.png");
      const logo = new ImageRun({
        data: logoBuffer,
        transformation: {
          width: 100,
          height: 100,
        },
      });
      let paragraphs = [];
      let memberPara = new Paragraph({
        children: [new TextRun({ text: `Lidnummer: 199902` })],
      });
      let datePara = new Paragraph({
        children: [new TextRun({ text: `Hulst, ${creationDate}`, break: 1 })],
      });
      let startPara = new Paragraph({
        children: [new TextRun({ text: `Beste`, break: 2 })],
      });
      paragraphs.push(memberPara);
      paragraphs.push(datePara);
      paragraphs.push(startPara);
      moment.letter.paragraphs.forEach((x) => {
        if (x.type == "text") {
          let para = new Paragraph({
            children: [new TextRun({ text: `${x.text}`, break: 1 })],
          });
          paragraphs.push(para);
        } else if (x.type == "paymentModule") {
          let para = new Paragraph({
            children: [
              new TextRun({
                text: `Betalings module`,
                break: 1,
                alignment: AlignmentType.CENTER,
              }),
            ],
          });
          paragraphs.push(para);
        }
      });
      const doc = new Document({
        creator: "Backoffice AHC de Vossen",
        description: `${moment.parent} - ${moment.title}`,
        title: `${moment.parent} - ${moment.title}`,
        sections: [
          {
            headers: {
              default: new Header({
                children: [
                  new Paragraph({
                    children: [logo],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
              }),
            },
            children: paragraphs,
          },
        ],
      });

      let filename = `Concept ${moment.parent} - ${moment.title}`;
      Packer.toBlob(doc).then((blob) => {
        saveAs(blob, filename);
      });
    },
    addParagraph() {
      let paragraph = {};
      if (!this.newContactMoment.payment) {
        paragraph.type = "text";
      }
      paragraph.index = this.paragraphIndex;
      this.paragraphIndex++;
      this.newContactMomentLetter.paragraphs.push(paragraph);
    },
    deleteContactMoment(parent, id) {
      try {
        let confirmation = window.confirm(
          "Weet je zeker dat je dit contact moment wilt verwijderen? Dit heeft gevolgen voor alle bijbehorende brieven en eventuele betalingen."
        );
        if (confirmation) {
          this.clubEditions.editions.forEach((x) => {
            if (x.title == parent) {
              x.contactMoments.forEach((y, i) => {
                if (y.id == id) {
                  x.contactMoments.splice(i, 1);
                }
              });
            }
          });
          this.$store.dispatch("saveClubEditions", {
            clubEditions: this.clubEditions,
          });
        } else {
          return;
        }
      } catch (error) {
        alert("deleteContactMoment is gefaald: " + error.message);
      }
    },
    executeMoment(moment, contribution) {
      this.generateOverlay = true;
      this.$store.dispatch("executeContactMoment", {
        moment: moment,
        contribution: contribution,
      });
      setTimeout(() => {
        this.generateOverlay = false;
      }, 30000);
    },
    checkContactMomentPayments(id){
      this.$store.dispatch("checkContactMomentPayments", {contactMomentID: id})
    },

    downloadReport(moment, type) {
      this.createReportOverlay = true;
      let info;
      if (moment) {
        info = {
          edition: moment.parent,
          id: moment.id,
          type: type,
        };
      } else {
        info = {
          type: type,
        };
      }

      let createClubReport = firebase
        .functions()
        .httpsCallable("createClubReport");
      createClubReport({ reportParams: info }).then((result) => {
        if (result.data.succes) {
          let file = firebase
            .storage()
            .ref(`club/reports/${result.data.reportID}`);
          file.getDownloadURL().then((url) => {
            Axios.get(url, { responseType: "blob" })
              .then(({ data }) => {
                const blob = new Blob([data], {
                  type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                });
                const url = document.createElement("a");
                url.href = URL.createObjectURL(blob);
                url.download = `${type} rapport`;
                url.click();
                URL.revokeObjectURL(url.href);
              })
              .catch((error) => console.log(error));
          });
          this.createReportOverlay = false;
        } else {
          alert(result.data.msg);
        }
      });
    },
    async downloadLetters(year, title, order) {
      this.downloadOverlay = true;
      //generate letters
      let zipLetters = new JSZip();
      let zipLettersName = `Brieven_${year}_${title.replace(/\s/g, "_")}`;
      let zipLettersFile = zipLetters.folder(zipLettersName);
      let index = 1;
      for (let i = 0; i < order.length; i++) {
        this.processedFile = `Aan het downloaden: ${title.replace(
          /\s/g,
          "_"
        )}/${order[i]}.docx`;
        let file = firebase
          .storage()
          .ref(
            `club/letters/${year}/${title.replace(/\s/g, "_")}/${order[i]}.docx`
          );

        let url = await file.getDownloadURL();
        await Axios.get(url, { responseType: "blob" })
          .then(({ data }) => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });
            zipLettersFile.file(
              `${index}_${order[i]}_${year}_${title.replace(/\s/g, "_")}.docx`,
              blob
            );
            index++;
          })
          .catch((error) => alert(error));
      }
      this.processedFile = `Zipfolder met brieven genereren`;
      zipLettersFile.generateAsync({ type: "blob" }).then((blob) => {
        saveAs(blob, zipLettersName);
      });
      //generate labels
      let zipLabels = new JSZip();
      let zipLabelsName = `Labels_${year}_${title.replace(/\s/g, "_")}`;
      let zipLabelsFile = zipLabels.folder(zipLabelsName);
      let labelAmount =
        order.length % 24 === 0
          ? parseInt(order.length / 24)
          : parseInt(order.length / 24) + 1;
      for (let i = 0; i < labelAmount; i++) {
        this.processedFile = `Aan het downloaden: ${title.replace(
          /\s/g,
          "_"
        )}/labels_${i + 1}.docx`;
        let file = firebase
          .storage()
          .ref(
            `club/letters/${year}/${title.replace(/\s/g, "_")}/labels_${
              i + 1
            }.docx`
          );

        let url = await file.getDownloadURL();
        await Axios.get(url, { responseType: "blob" })
          .then(({ data }) => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });
            zipLabelsFile.file(
              `${i}_labels_${year}_${title.replace(/\s/g, "_")}.docx`,
              blob
            );
          })
          .catch((error) => alert(error));
      }
      this.processedFile = `Zipfolder met labels genereren`;
      zipLabelsFile.generateAsync({ type: "blob" }).then((blob) => {
        saveAs(blob, zipLabelsName);
      });
      this.processedFile = "";
      this.downloadOverlay = false;
    },
  },
  beforeCreate() {
    this.$store.dispatch("loadClubEditions").then(() => {
      this.$store.dispatch("loadClubMembers");
    });
  },

};
</script>

<style>
</style>