<template>
  <v-container>
    <h1 class="ma-4 d-flex">
      <span class="ahcRed">Test Area</span>
    </h1>
    <v-btn>
      <vue-internet-checker @status="status" @event="event" />
    </v-btn>

    <v-flex class="ma-2" xs12 name="flexToDo">
      <v-card>
        <v-system-bar class="pa-4" color="#ebb63b">
          <span>
            <b>To Do list webmaster</b>
          </span>
        </v-system-bar>
        <v-list-item v-for="(toDo, i) in toDos" :key="i">
          <v-list-item-content>
            <v-list-item-title>{{ toDo }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-flex>
  </v-container>
</template>

<script>
import vueInternetChecker from "vue-internet-checker";
export default {
  components: {
    vueInternetChecker,
  },
  data() {
    return {
      toDos: [
        "Newsitems: Later publiceren toevoegen",
        "Newsitems: Aanpassen toevoegen",
        "Newsitems: Foto's toevoegen",
        "Newsitems: Links toevoegen",
        "Agenda fancy (en slim) maken",
        "Wist u dat toevoegen",
        "Thema suggestie toevoegen",
        "Leuke links",
      ],
      onLine: null,
    };
  },
  computed: {},
  methods: {
    status(ele) {
      console.log(ele);
      this.onLine = ele;
    },
    event(ele) {
      console.log(ele);
    },

    //
  },
};
</script>

<style>
</style>