<template>
  <v-container>
    <v-footer class="d-flex justify-space-between" padless color="#cc212f">
      <span class="white--text px-3">
        &#169;{{ new Date().getFullYear() }} —
        <strong>JSA-it</strong>
      </span>
      <span @click="problemDialog = true" class="white--text px-3">
        <strong>
          <u>probleem/opmerking/tip</u>
        </strong>
      </span>
    </v-footer>
    <v-dialog
      transition="dialog-top-transition"
      max-width="500"
      v-model="problemDialog"
    >
      <v-card>
        <v-toolbar color="#ebb63b" class="text--white">
          <span
            >Beschrijf de situatie of het probleem zo nauwkeurig mogelijk.</span
          >
          <v-spacer></v-spacer>
          <v-icon @click="(problem = {}), (problemDialog = null)"
            >mdi-close-outline</v-icon
          >
        </v-toolbar>
        <v-form class="ma-3">
          <v-textarea
            v-model="problem.msg"
            label="probleem/opmerking/tip: "
          ></v-textarea>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="(problemDialog = false), (problem = {})"
            >Annuleren</v-btn
          >

          <v-btn color="green darken-1" text @click="sendProblem()"
            >Verzenden</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "Bottom",
  data() {
    return {
      problemDialog: false,
      problem: {},
    };
  },
  methods: {
    sendProblem() {
      this.problem.author = this.$store.state.currentUser.name;
      this.problem.route = this.$route.path;
      this.problem.store = this.$store.state;

      let currentdate = new Date();
      this.problem.date =
        currentdate.getDate() +
        "/" +
        (currentdate.getMonth() + 1) +
        "/" +
        currentdate.getFullYear() +
        " @ " +
        currentdate.getHours() +
        ":" +
        currentdate.getMinutes() +
        ":" +
        currentdate.getSeconds();

      this.$store.dispatch("sendProblem", { problem: this.problem });
      this.problemDialog = false;
      this.problem = {};
    },
  },
};
</script>

<style>
.ahcRed {
  color: #cc212f;
}
</style>