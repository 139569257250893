<template>
  <v-container>
    <v-layout row wrap xs12 class="ma-2 justify-space-between">
      <h1 class="ma-4 d-flex">
        <span class="ahcRed">Financiën</span>
      </h1>
    </v-layout>
    <v-layout row wrap v-if="secretary" name="layoutFinancial">
      <v-flex xs12 md5 class="ma-3" name="flexDeclaratieAccountsPaperFacoMod">
        <v-flex xs12 class="ma-3" name="flexAccounts">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Rekeningnummers</b>
              </span>
            </v-system-bar>
            <v-card-text>
              <span><b>Algemeen:</b> NL79 RABO 0150790759<br /></span>
              <span><b>Club van 111:</b> NL59 RABO 0100928269<br /></span>
              <span><b>Krant:</b> NL33 RABO 0330013734<br /></span>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 md5 class="ma-3" name="flexDocsDB">
        <v-flex xs12 class="ma-3" name="flexDocs">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Documenten</b>
              </span>
              <v-spacer></v-spacer>
              <div
                class="d-flex"
                v-if="currentUser.memberOf.includes('1') && !addFinancialDoc"
              >
                <v-icon color="#cc212f" @click="addFinancialDoc = true"
                  >mdi-file-plus-outline</v-icon
                >
              </div>
            </v-system-bar>
            <v-card-text
              v-if="
                !addFinancialDoc && secretary.docs.financial.docs.length == 0
              "
            >
              <span>
                Er zijn nog geen documenten geupload. Het is de taak van de
                penningmeester om dit te verzorgen.
              </span>
            </v-card-text>
            <v-form class="pa-2" v-if="addFinancialDoc">
              <h4>Nieuw document uploaden</h4>
              <v-text-field
                label="Titel (verplicht)"
                v-model="newDoc.title"
              ></v-text-field>
              <v-textarea
                label="Beschrijving (optioneel)"
                v-model="newDoc.description"
              >
              </v-textarea>
              <v-file-input
                label="te uploaden document: "
                v-model="newDoc.doc"
                accept=".pdf,.doc,.docx,.xml,.xls,.xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ></v-file-input>
              <v-btn @click="(addFinancialDoc = false), (newDoc = {})"
                >Annuleren</v-btn
              >
              <v-btn
                v-if="newDoc.title && newDoc.doc"
                @click="saveFinancialDoc()"
                >Opslaan</v-btn
              >
            </v-form>
            <v-list
              v-if="
                secretary.docs.financial.docs.length > 0 && !addFinancialDoc
              "
              three-line
            >
              <v-list-item
                v-for="(doc, i) in secretary.docs.financial.docs"
                :key="i"
              >
                <v-list-item-icon
                  @click="downloadFile(doc.title, doc.doc_id, doc.type)"
                >
                  <v-icon>mdi-file-download-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ doc.title }}</v-list-item-title>
                  <v-list-item-subtitle v-if="doc.description">{{
                    doc.description
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle
                    ><small
                      >{{ doc.creationDate }} - {{ doc.creator }}</small
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-icon
                  v-if="currentUser.memberOf.includes('1')"
                  @click="
                    (deleteFinancialDocDialog = true),
                      (deleteFinancialDoc = doc.id)
                  "
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-card>
        </v-flex>
        <v-flex
          xs12
          class="ma-3"
          name="flexDB"
          v-if="currentUser.memberOf.includes('1')"
        >
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Documenten (DB only)</b>
              </span>
              <v-spacer></v-spacer>
              <div
                class="d-flex"
                v-if="currentUser.memberOf.includes('1') && !addDBdoc"
              >
                <v-icon color="#cc212f" @click="addDBdoc = true"
                  >mdi-file-plus-outline</v-icon
                >
              </div>
            </v-system-bar>
            <v-card-text
              v-if="!addDBdoc && secretary.docs.financial.dbdocs.length == 0"
            >
              <span>
                Er zijn nog geen documenten geupload. Het is de taak van de
                penningmeester om dit te verzorgen.
              </span>
            </v-card-text>
            <v-form class="pa-2" v-if="addDBdoc">
              <h4>Nieuw document uploaden</h4>
              <v-text-field
                label="Titel (verplicht)"
                v-model="newDBdoc.title"
              ></v-text-field>
              <v-textarea
                label="Beschrijving (optioneel)"
                v-model="newDBdoc.description"
              >
              </v-textarea>
              <v-radio-group v-model="addDBdocRadiogroup">
                <v-radio :value="0" label="Document (word, excel, ppx, etc..)"></v-radio>
                <v-radio :value="1"
                  label="Link (Google doc, Google spreadsheet, etc..)"
                ></v-radio>
              </v-radio-group>
              <v-file-input
                v-if="addDBdocRadiogroup == 0"
                label="te uploaden document: "
                v-model="newDBdoc.doc"
                accept=".pdf,.doc,.docx,.xml,.xls,.xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ></v-file-input>
              <v-text-field
                v-if="addDBdocRadiogroup == 1"
                label="link: "
                v-model="newDBdoc.link"
              ></v-text-field>
              <v-btn @click="(addDBdoc = false), (newDBdoc = {})"
                >Annuleren</v-btn
              >
              <v-btn
                v-if="newDBdoc.title && (newDBdoc.doc || newDBdoc.link)"
                @click="saveDBdoc()"
                >Opslaan</v-btn
              >
            </v-form>
            <v-list
              v-if="secretary.docs.financial.dbdocs.length > 0 && !addDBdoc"
              three-line
            >
              <v-list-item
                v-for="(doc, i) in secretary.docs.financial.dbdocs"
                :key="i"
              >
                <v-list-item-icon v-if="doc.link">
                  <a :href="'//' + doc.link" target="_blank">
                    <v-icon>mdi-open-in-new </v-icon>
                  </a>
                </v-list-item-icon>
                <v-list-item-icon
                  v-if="doc.doc_id"
                  @click="downloadFile(doc.title, doc.doc_id, doc.type)"
                >
                  <v-icon>mdi-file-download-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ doc.title }}</v-list-item-title>
                  <v-list-item-subtitle v-if="doc.description">{{
                    doc.description
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle
                    ><small
                      >{{ doc.creationDate }} - {{ doc.creator }}</small
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-icon
                  @click="(deleteDBdocDialog = true), (deleteDBdoc = doc.id)"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-card>
        </v-flex>
      </v-flex>
      <v-flex
        xs12
        class="ma-3"
        name="flexAccountancy"
        v-if="(currentUser.memberOf.includes('1') || currentUser.role == 'Admin') && accountancy"
      >
        <v-flex xs12 class="ma-3" name="flexAccountancy">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Boekhouding commissies</b>
              </span>
              <v-spacer></v-spacer>
              <!-- <div class="d-flex" v-if="!addAccountancyYearDialog">
                <v-icon color="#cc212f" @click="addAccountancyYearDialog = true"
                  >mdi-plus-outline</v-icon
                >
              </div> -->
            </v-system-bar>
            <v-tabs color="#cc212f">
              <v-tab
                v-model="yearTab"
                v-for="(year, i) in accountancy.years"
                :key="i"
                >{{ year.title }}</v-tab
              >
              <v-tab-item v-for="(year, i) in accountancy.years" :key="i">
                <v-card-text>
                  <v-switch
                    v-model="year.locked"
                    :label="`${
                      year.locked
                        ? 'Declaraties geblokkeerd'
                        : 'Declaraties toegestaan'
                    }`"
                    @click="saveAccountancy()"
                    color="#cc212f"
                  ></v-switch>
                  <v-alert outlined color="#ebb63b">
                    <v-flex xs12 class="ma-2">
                      <v-row>
                        <v-col>
                          <v-card></v-card>
                        </v-col>
                        <v-col>
                          <v-card flat><h3>Uitgaven</h3></v-card>
                        </v-col>
                        <v-col>
                          <v-card flat><h3>Inkomsten</h3></v-card>
                        </v-col>
                        <v-col>
                          <v-card flat><h3>Resultaat</h3></v-card>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-card flat><h3>Begroot</h3></v-card>
                        </v-col>
                        <v-col>
                          <v-card flat
                            ><v-chip color="#ebb63b" text-color="#cc212f"
                              >€{{
                                year.totalExpencesBudget.toFixed(2)
                              }}</v-chip
                            ></v-card
                          >
                        </v-col>
                        <v-col>
                          <v-card flat
                            ><v-chip color="#ebb63b" text-color="#cc212f"
                              >€{{ year.totalIncomeBudget.toFixed(2) }}</v-chip
                            ></v-card
                          >
                        </v-col>
                        <v-col>
                          <v-card flat
                            ><v-chip color="#ebb63b" text-color="#cc212f"
                              >€{{
                                (
                                  year.totalIncomeBudget -
                                  year.totalExpencesBudget
                                ).toFixed(2)
                              }}</v-chip
                            ></v-card
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-card flat
                            ><h3>Werkelijk</h3>
                            <small>(Tot op heden)</small></v-card
                          >
                        </v-col>
                        <v-col>
                          <v-card flat
                            ><v-chip color="#ebb63b" text-color="#cc212f"
                              >€{{ year.totalExpences.toFixed(2) }}</v-chip
                            ></v-card
                          >
                        </v-col>
                        <v-col>
                          <v-card flat
                            ><v-chip color="#ebb63b" text-color="#cc212f"
                              >€{{ year.totalIncome.toFixed(2) }}</v-chip
                            ></v-card
                          >
                        </v-col>
                        <v-col>
                          <v-card flat
                            ><v-chip color="#ebb63b" text-color="#cc212f"
                              >€{{
                                (year.totalIncome - year.totalExpences).toFixed(
                                  2
                                )
                              }}</v-chip
                            ></v-card
                          >
                        </v-col>
                      </v-row>
                    </v-flex>
                  </v-alert>
                  <v-expansion-panels>
                    <v-expansion-panel
                      @change="(editWGBudget = false), (editWGIncome = false)"
                      v-for="(wg, j) in year.workgroups"
                      :key="j"
                    >
                      <v-expansion-panel-header>
                        <div class="d-flex justify-space-between">
                          <b>C{{ wg.index }} - {{ wg.title }}</b>

                          <span
                            class="ahcRed"
                            v-if="wg.declarations.open.length > 0"
                            ><b>{{ wg.declarations.open.length }}</b></span
                          >
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="mx-5">
                        <div class="d-flex" v-if="!editWGBudget">
                          <span
                            >Begrote kosten:
                            <b>€{{ wg.expencesBudget }}</b></span
                          >
                          <v-icon
                            color="grey"
                            size="small"
                            class="mx-3"
                            @click="editWGBudget = true"
                            >mdi-pencil-outline</v-icon
                          >
                        </div>
                        <div class="d-flex" v-if="editWGBudget">
                          <span
                            >Begrote kosten:
                            <v-text-field
                              type="number"
                              v-model="wg.expencesBudget"
                            ></v-text-field
                          ></span>
                          <v-icon
                            color="grey"
                            size="small"
                            class="mx-3"
                            @click="(editWGBudget = false), saveAccountancy()"
                            >mdi-content-save</v-icon
                          >
                        </div>
                        <div class="d-flex" v-if="!editWGIncome">
                          <span
                            >Begrote inkomsten:
                            <b>€{{ wg.incomeBudget }}</b></span
                          >
                          <v-icon
                            color="grey"
                            size="small"
                            class="mx-3"
                            @click="editWGIncome = true"
                            >mdi-pencil-outline</v-icon
                          >
                        </div>
                        <div class="d-flex" v-if="editWGIncome">
                          <span
                            >Begrote inkomsten:
                            <v-text-field
                              type="number"
                              v-model="wg.incomeBudget"
                            ></v-text-field
                          ></span>
                          <v-icon
                            color="grey"
                            size="small"
                            class="mx-3"
                            @click="(editWGIncome = false), saveAccountancy()"
                            >mdi-content-save</v-icon
                          >
                        </div>
                        <span
                          >Kosten tot op heden: <b>€{{ wg.expences }}</b
                          ><br
                        /></span>
                        <span
                          >Inkomsten tot op heden: <b>€{{ wg.income }}</b
                          ><br
                        /></span>
                        <span
                          >Begroot resultaat:
                          <b>€{{ wg.incomeBudget - wg.expencesBudget }}</b
                          ><br
                        /></span>
                        <span
                          >Werkelijk resultaat:
                          <b>€{{ wg.income - wg.expences }}</b
                          ><br
                        /></span>
                        <v-btn
                          class="my-3"
                          v-if="!addMutation"
                          @click="
                            (addMutation = true),
                              (mutation.index = wg.index),
                              (mutation.year = year.index)
                          "
                        >
                          <v-icon>mdi-plus-circle</v-icon>
                          <span>Mutatie toevoegen</span>
                        </v-btn>

                        <v-card class="my-3" v-if="addMutation">
                          <v-system-bar class="pa-4">
                            <span>
                              <b>Transactie toevoegen</b>
                            </span>
                          </v-system-bar>
                          <v-form class="mx-3">
                            <v-text-field
                              v-model="mutation.description"
                              label="Beschrijving"
                            ></v-text-field>
                            <v-radio-group v-model="mutationRadiogroup">
                              <v-radio :value="0" label="Inkomsten"></v-radio>
                              <v-radio :value="1" label="Uitgaven"></v-radio>
                            </v-radio-group>
                            <v-text-field
                              v-if="mutationRadiogroup == 0"
                              label="Bedrag aan inkomsten: "
                              type="number"
                              v-model="mutation.income"
                            ></v-text-field>
                            <v-text-field
                              v-if="mutationRadiogroup == 1"
                              label="Bedrag aan uitgaven: "
                              type="number"
                              v-model="mutation.expence"
                            ></v-text-field>
                            <v-row justify="center">
                              <v-btn
                                @click="saveMutation"
                                outlined
                                rounded
                                text
                                class="ma-2"
                              >
                                <span>Toevoegen</span>
                                <v-icon small right
                                  >mdi-account-plus-outline</v-icon
                                >
                              </v-btn>
                              <v-btn
                                @click="(addMutation = false), (mutation = {})"
                                outlined
                                rounded
                                text
                                class="ma-2"
                              >
                                <span>Annuleren</span>
                                <v-icon small right
                                  >mdi-arrow-left-circle</v-icon
                                >
                              </v-btn>
                            </v-row>
                          </v-form>
                        </v-card>
                        <v-card class="my-2">
                          <v-system-bar class="pa-4">
                            <span>
                              <b>Overzicht transacties</b>
                            </span>
                          </v-system-bar>
                          <v-expansion-panels flat>
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                <span><b>Openstaande declaraties</b></span>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-list
                                  v-if="wg.declarations.open.length > 0"
                                  two
                                  line
                                >
                                  <v-list-item
                                    v-for="(transaction, k) in wg.declarations
                                      .open"
                                    :key="k"
                                  >
                                    <v-list-item-icon
                                      v-if="transaction.amount < 0"
                                      ><v-icon color="red"
                                        >mdi-arrow-down-bold</v-icon
                                      >
                                    </v-list-item-icon>
                                    <v-list-item-icon
                                      v-if="transaction.amount > 0"
                                      ><v-icon color="green"
                                        >mdi-arrow-up-bold</v-icon
                                      ></v-list-item-icon
                                    >
                                    <v-list-item-icon
                                      v-if="transaction.receiptType"
                                      @click="
                                        downloadReceipt(
                                          transaction.id,
                                          transaction.receiptType,
                                          wg.index
                                        )
                                      "
                                    >
                                      <v-icon>mdi-file-download-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        >€{{ transaction.amount.toFixed(2) }}
                                        {{
                                          transaction.description
                                        }}</v-list-item-title
                                      >
                                      <v-list-item-subtitle
                                        ><small
                                          >{{ transaction.creationDate }} -
                                          {{ transaction.creator }}</small
                                        ></v-list-item-subtitle
                                      >
                                    </v-list-item-content>
                                    <v-list-item-icon
                                      @click="
                                        approveTransaction(
                                          transaction,
                                          wg.index,
                                          year.index
                                        )
                                      "
                                    >
                                      <v-btn
                                        ><v-icon color="green"
                                          >mdi-check-bold</v-icon
                                        ></v-btn
                                      >
                                    </v-list-item-icon>
                                    <v-list-item-icon
                                      @click="
                                        (declineTransaction = {
                                          transaction: transaction,
                                          wg: wg.index,
                                          year: year.index,
                                        }),
                                          (declineTransactionDialog = true)
                                      "
                                    >
                                      <v-btn
                                        ><v-icon color="red"
                                          >mdi-close-thick</v-icon
                                        ></v-btn
                                      >
                                    </v-list-item-icon>
                                  </v-list-item>
                                </v-list>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                <span><b>Afgesloten declaraties</b></span>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-list
                                  v-if="wg.declarations.closed.length > 0"
                                  three
                                  line
                                >
                                  <v-list-item
                                    v-for="(transaction, k) in wg.declarations
                                      .closed"
                                    :key="k"
                                  >
                                    <v-list-item-icon
                                      v-if="
                                        transaction.amount < 0 &&
                                        !transaction.reason
                                      "
                                      ><v-icon color="red"
                                        >mdi-arrow-down-bold</v-icon
                                      >
                                    </v-list-item-icon>
                                    <v-list-item-icon
                                      v-if="
                                        transaction.amount > 0 &&
                                        !transaction.reason
                                      "
                                      ><v-icon color="green"
                                        >mdi-arrow-up-bold</v-icon
                                      ></v-list-item-icon
                                    >
                                    <v-list-item-icon v-if="transaction.reason"
                                      ><v-icon color="red"
                                        >mdi-close-thick</v-icon
                                      ></v-list-item-icon
                                    >
                                    <v-list-item-icon
                                      v-if="transaction.receiptType"
                                      @click="
                                        downloadReceipt(
                                          transaction.id,
                                          transaction.receiptType,
                                          wg.index
                                        )
                                      "
                                    >
                                      <v-icon>mdi-file-download-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        >€{{ transaction.amount.toFixed(2) }}
                                        {{
                                          transaction.description
                                        }}</v-list-item-title
                                      >
                                      <v-list-item-subtitle
                                        v-if="transaction.reason"
                                        ><small
                                          >{{ transaction.reason }}
                                        </small></v-list-item-subtitle
                                      >
                                      <v-list-item-subtitle
                                        ><small
                                          >{{ transaction.creationDate }} -
                                          {{ transaction.creator }}</small
                                        ></v-list-item-subtitle
                                      >
                                    </v-list-item-content>
                                    <v-list-item-icon
                                      @click="
                                        (delTransaction = {
                                          id: transaction.id,
                                          wg: wg.index,
                                          year: year.index,
                                          status: 'closed',
                                          receiptType: transaction.receiptType,
                                        }),
                                          (deleteTransactionDialog = true)
                                      "
                                    >
                                      <v-icon>mdi-delete-outline</v-icon>
                                    </v-list-item-icon>
                                  </v-list-item>
                                </v-list>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-card class="ma-5" elevation="0" v-if="!secretary">
      <v-row class="d-flex justify-space-around mb-6">
        <v-progress-circular
          class="ma-5 ahcRed"
          :size="70"
          :width="7"
          indeterminate
        ></v-progress-circular>
      </v-row>
      <v-row class="d-flex justify-space-around mb-6">
        <h3>Inladen documenten...</h3>
      </v-row>
    </v-card>
    <v-dialog
      transition="dialog-top-transition"
      max-width="300"
      v-model="deleteDeclarationFormDialog"
    >
      <v-card v-if="!buzzy">
        <v-card-title class="headerClass"
          >Declaratieformulier verwijderen?</v-card-title
        >
        <v-card-text>Deze actie is onomkeerbaar</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="
              (deleteDeclarationFormDialog = false),
                (deleteDeclarationForm = null)
            "
            >Annuleren</v-btn
          >

          <v-btn
            color="green darken-1"
            text
            @click="deleteDeclarationFormF(deleteDeclarationForm)"
            >Verwijderen</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="ma-5" elevation="0" v-if="buzzy">
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular
            class="ma-5 ahcRed"
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Verwijderen document</h3>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-top-transition"
      max-width="300"
      v-model="deleteFinancialDocDialog"
    >
      <v-card v-if="!buzzy">
        <v-card-title class="headerClass">Document verwijderen?</v-card-title>
        <v-card-text>Deze actie is onomkeerbaar</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="
              (deleteFinancialDocDialog = false), (deleteFinancialDoc = null)
            "
            >Annuleren</v-btn
          >

          <v-btn
            color="green darken-1"
            text
            @click="deleteFinancialDocF(deleteFinancialDoc)"
            >Verwijderen</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="ma-5" elevation="0" v-if="buzzy">
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular
            class="ma-5 ahcRed"
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Verwijderen document</h3>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-top-transition"
      max-width="300"
      v-model="deleteDBdocDialog"
    >
      <v-card v-if="!buzzy">
        <v-card-title class="headerClass">Document verwijderen?</v-card-title>
        <v-card-text>Deze actie is onomkeerbaar</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="(deleteDBdocDialog = false), (deleteDBdoc = null)"
            >Annuleren</v-btn
          >

          <v-btn color="green darken-1" text @click="deleteDBdocF(deleteDBdoc)"
            >Verwijderen</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="ma-5" elevation="0" v-if="buzzy">
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular
            class="ma-5 ahcRed"
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Verwijderen document</h3>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-top-transition"
      max-width="300"
      v-model="addAccountancyYearDialog"
    >
      <v-card v-if="!buzzy">
        <v-card-title class="headerClass">Nieuw boekjaar starten</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newAccountancyYear.title"
            label="Titel"
            placeholder="bv: 2021 / 2022"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="
              (addAccountancyYearDialog = false), (newAccountancyYear = {})
            "
            >Annuleren</v-btn
          >

          <v-btn
            color="green darken-1"
            text
            v-if="newAccountancyYear.title"
            @click="addAccountancyYear()"
            >Creëren</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="ma-5" elevation="0" v-if="buzzy">
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular
            class="ma-5 ahcRed"
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Nieuw boekjaar creëren</h3>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-top-transition"
      max-width="300"
      v-model="deleteTransactionDialog"
    >
      <v-card v-if="!buzzy">
        <v-card-title class="headerClass">Transactie verwijderen?</v-card-title>
        <v-card-text>Deze actie is onomkeerbaar</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="(deleteTransactionDialog = false), (delTransaction = null)"
            >Annuleren</v-btn
          >

          <v-btn
            color="green darken-1"
            text
            @click="
              deleteTransaction(
                delTransaction.id,
                delTransaction.wg,
                delTransaction.year,
                delTransaction.status,
                delTransaction.receiptType
              )
            "
            >Verwijderen</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="ma-5" elevation="0" v-if="buzzy">
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular
            class="ma-5 ahcRed"
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Verwijderen transactie</h3>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-top-transition"
      max-width="500"
      v-model="declineTransactionDialog"
    >
      <v-card v-if="!buzzy">
        <v-card-title class="headerClass"
          >Wat is de reden om de transactie te wijgeren?</v-card-title
        >
        <v-card-text>
          <v-text-field v-model="declineReason"></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="
              (declineTransactionDialog = false),
                (declineTransaction = null),
                (declineReason = null)
            "
            >Annuleren</v-btn
          >

          <v-btn
            v-if="declineReason"
            color="green darken-1"
            text
            @click="
              declineTransactionF(
                declineTransaction.transaction,
                declineTransaction.wg,
                declineTransaction.year
              )
            "
            >Wijgeren</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="ma-5" elevation="0" v-if="buzzy">
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular
            class="ma-5 ahcRed"
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Transactie wijgeren</h3>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import firebase from "firebase";
import Axios from "axios";
export default {
  components: {
  },
  data() {
    return {
      newDeclarationForm: {},
      newDoc: {},
      addDeclarationForm: false,
      addFinancialDoc: false,
      deleteDeclarationForm: null,
      deleteFinancialDoc: null,
      deleteDeclarationFormDialog: false,
      deleteFinancialDocDialog: false,
      deleteTransactionDialog: false,
      delTransaction: null,
      newDBdoc: {},
      addDBdoc: false,
      deleteDBdoc: null,
      deleteDBdocDialog: false,
      addDBdocRadiogroup: 0,
      buzzy: false,
      addAccountancyYearDialog: false,
      newAccountancyYear: {},
      yearTab: 0,
      editWGBudget: false,
      editWGIncome: false,
      addMutation: false,
      mutation: {},
      mutationRadiogroup: 0,
      declineTransactionDialog: false,
      declineTransaction: null,
      declineReason: null,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    secretary() {
      return this.$store.state.secretary;
    },
    accountancy() {
      return this.$store.state.accountancy;
    },
    userName() {
      return this.$store.state.currentUser.name;
    },
  },
  methods: {
    saveNewDeclarationForm() {
      try {
        let id = Date.now();
        let creationDate = new Date();
        let dd = String(creationDate.getDate()).padStart(2, "0");
        let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = creationDate.getFullYear();
        creationDate = dd + "/" + mm + "/" + yyyy;
        let creator = this.userName;

        let declarationForm = {
          id: id,
          title: "Declaratieformulier AHC de Vossen",
          doc_id: id,
          creator: creator,
          creationDate: creationDate,
          type: this.newDeclarationForm.doc.name.split(".").pop(),
        };

        let storageRef = firebase.storage().ref();
        storageRef
          .child(`docs/secretary/${id}`)
          .put(this.newDeclarationForm.doc);
        this.secretary.docs.financial.declaration.push(declarationForm);
        this.$store.dispatch("saveSecretary");
        this.newDeclarationForm = {};
        this.addDeclarationForm = false;
      } catch (error) {
        console.log(error.message);
      }
    },
    saveFinancialDoc() {
      try {
        let id = Date.now();
        let creationDate = new Date();
        let dd = String(creationDate.getDate()).padStart(2, "0");
        let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = creationDate.getFullYear();
        creationDate = dd + "/" + mm + "/" + yyyy;
        let creator = this.userName;
        let financialDoc = {
          id: id,
          title: this.newDoc.title,
          description: this.newDoc.description,
          doc_id: id,
          creator: creator,
          creationDate: creationDate,
          type: this.newDoc.doc.name.split(".").pop(),
        };

        let storageRef = firebase.storage().ref();
        storageRef.child(`docs/secretary/${id}`).put(this.newDoc.doc);
        this.secretary.docs.financial.docs.push(financialDoc);
        this.$store.dispatch("saveSecretary");
        this.newDoc = {};
        this.addFinancialDoc = false;
      } catch (error) {
        console.log(error.message);
      }
    },
    saveDBdoc() {
      try {
        let id = Date.now();
        let creationDate = new Date();
        let dd = String(creationDate.getDate()).padStart(2, "0");
        let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = creationDate.getFullYear();
        creationDate = dd + "/" + mm + "/" + yyyy;
        let creator = this.userName;
        if (this.newDBdoc.link) {
          this.newDBdoc.link = this.newDBdoc.link.replace("https://", "");
          this.newDBdoc.id = id;
          this.newDBdoc.creator = creator;
          this.newDBdoc.creationDate = creationDate;
          this.secretary.docs.financial.dbdocs.push(this.newDBdoc);
          this.$store.dispatch("saveSecretary");
          this.newDBdoc = {};
          this.addDBdoc = false;
        } else if (this.newDBdoc.doc) {
          let doc = {
            id: id,
            title: this.newDBdoc.title,
            description: this.newDBdoc.description,
            doc_id: id,
            creator: creator,
            creationDate: creationDate,
            type: this.newDBdoc.doc.name.split(".").pop(),
          };

          let storageRef = firebase.storage().ref();
          storageRef.child(`docs/secretary/${id}`).put(this.newDoc.doc);
          this.secretary.docs.financial.dbdocs.push(doc);
          this.$store.dispatch("saveSecretary");
          this.newDBdoc = {};
          this.addDBdoc = false;
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    downloadFile(title, id, file_type) {
      let metadata;
      if (file_type === ("doc" || "dot")) {
        metadata = "application/msword";
      } else if (file_type === "docx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      } else if (file_type === ("xls" || "xlt" || "xla")) {
        metadata = "application/vnd.ms-excel";
      } else if (file_type === "xlsx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      } else if (file_type === ("ppt" || "pot" || "pps" || "ppa")) {
        metadata = "application/vnd.ms-powerpoint";
      } else if (file_type === "pptx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      } else if (file_type === "mdb") {
        metadata = "application/vnd.ms-access";
      } else if (file_type === "pdf") {
        metadata = "application/pdf";
      }

      let filename = title;
      let file = firebase.storage().ref(`docs/secretary/${id}`);
      file.getDownloadURL().then((url) => {
        Axios.get(url, { responseType: "blob" })
          .then(({ data }) => {
            const blob = new Blob([data], {
              type: metadata,
            });

            const url = document.createElement("a");
            url.href = URL.createObjectURL(blob);
            url.download = filename;
            url.click();
            URL.revokeObjectURL(url.href);
          })
          .catch((error) => alert(error));
      });
    },
    deleteDeclarationFormF(id) {
      this.buzzy = true;
      for (
        let i = 0;
        i < this.secretary.docs.financial.declaration.length;
        i++
      ) {
        if (this.secretary.docs.financial.declaration[i].id === id) {
          if (this.secretary.docs.financial.declaration[i].doc_id) {
            let storageRef = firebase.storage().ref();
            storageRef.child(`docs/secretary/${id}`).delete();
          }
          this.secretary.docs.financial.declaration.splice(i, 1);
          this.$store.dispatch("saveSecretary");
        }
      }
      this.deleteDeclarationForm = null;
      setTimeout(() => {
        this.deleteDeclarationFormDialog = false;
        this.buzzy = false;
      }, 2000);
    },
    deleteFinancialDocF(id) {
      this.buzzy = true;
      for (let i = 0; i < this.secretary.docs.financial.docs.length; i++) {
        if (this.secretary.docs.financial.docs[i].id === id) {
          if (this.secretary.docs.financial.docs[i].doc_id) {
            let storageRef = firebase.storage().ref();
            storageRef.child(`docs/secretary/${id}`).delete();
          }
          this.secretary.docs.financial.docs.splice(i, 1);
          this.$store.dispatch("saveSecretary");
        }
      }
      this.deleteFinancialDoc = null;
      setTimeout(() => {
        this.deleteFinancialDocDialog = false;
        this.buzzy = false;
      }, 2000);
    },
    deleteDBdocF(id) {
      this.buzzy = true;
      for (let i = 0; i < this.secretary.docs.financial.dbdocs.length; i++) {
        if (this.secretary.docs.financial.dbdocs[i].id === id) {
          if (this.secretary.docs.financial.dbdocs[i].doc_id) {
            let storageRef = firebase.storage().ref();
            storageRef.child(`docs/secretary/${id}`).delete();
          }
          this.secretary.docs.financial.dbdocs.splice(i, 1);
          this.$store.dispatch("saveSecretary");
        }
      }
      this.deleteDBdoc = null;
      setTimeout(() => {
        this.deleteDBdocDialog = false;
        this.buzzy = false;
      }, 2000);
    },
    addAccountancyYear() {
      this.buzzy = true;
      this.$store.dispatch("addAccountancyYear", {
        accountancy: this.$store.state.accountancy,
        title: this.newAccountancyYear.title,
      });
      this.newAccountancyYear = {};
      setTimeout(() => {
        this.addAccountancyYearDialog = false;
        this.buzzy = false;
      }, 2000);
    },
    saveAccountancy() {
      this.$store.dispatch("saveAccountancy");
    },
    saveMutation() {
      let id = Date.now();
      let creationDate = new Date();
      let dd = String(creationDate.getDate()).padStart(2, "0");
      let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = creationDate.getFullYear();
      creationDate = dd + "/" + mm + "/" + yyyy;
      let amount = 0;
      if (this.mutationRadiogroup === 0) {
        amount += parseFloat(this.mutation.income);
      } else {
        amount -= parseFloat(this.mutation.expence);
      }
      let transaction = {
        id: id,
        description: this.mutation.description,
        creator: this.currentUser.name,
        creationDate: creationDate,
        amount: amount,
        status: true,
        reason: null,
      };
      for (let j = 0; j < this.accountancy.years.length; j++) {
        if (this.accountancy.years[j].index == this.mutation.year) {
          for (
            let i = 0;
            i < this.accountancy.years[j].workgroups.length;
            i++
          ) {
            if (
              this.accountancy.years[j].workgroups[i].index ===
              this.mutation.index
            ) {
              this.accountancy.years[j].workgroups[i].declarations.closed.push(
                transaction
              );
            }
          }
        }
      }

      this.$store.dispatch("saveAccountancy");
      setTimeout(() => {
        this.mutation = {};
        this.addMutation = false;
      }, 1000);
    },
    deleteTransaction(transactionID, wgID, yearID, type, receiptType) {
      this.buzzy = true;
      for (let j = 0; j < this.accountancy.years.length; j++) {
        if (this.accountancy.years[j].index == yearID) {
          for (
            let i = 0;
            i < this.accountancy.years[j].workgroups.length;
            i++
          ) {
            if (this.accountancy.years[j].workgroups[i].index === wgID) {
              if (type === "closed") {
                for (
                  let k = 0;
                  k <
                  this.accountancy.years[j].workgroups[i].declarations.closed
                    .length;
                  k++
                ) {
                  if (
                    this.accountancy.years[j].workgroups[i].declarations.closed[
                      k
                    ].id === transactionID
                  ) {
                    this.accountancy.years[j].workgroups[
                      i
                    ].declarations.closed.splice(k, 1);
                  }
                }
              }
            }
          }
        }
      }
      if (receiptType) {
        let storageRef = firebase.storage().ref();
        storageRef.child(`accountancy/C${wgID}/${transactionID}`).delete();
      }
      this.$store.dispatch("saveAccountancy");
      this.delTransaction = null;
      setTimeout(() => {
        this.deleteTransactionDialog = false;
        this.buzzy = false;
      }, 2000);
    },
    approveTransaction(transaction, wg, year) {
      try {
        for (let j = 0; j < this.accountancy.years.length; j++) {
          if (this.accountancy.years[j].index == year) {
            for (
              let i = 0;
              i < this.accountancy.years[j].workgroups.length;
              i++
            ) {
              if (this.accountancy.years[j].workgroups[i].index === wg) {
                for (
                  let k = 0;
                  k <
                  this.accountancy.years[j].workgroups[i].declarations.open
                    .length;
                  k++
                ) {
                  if (
                    this.accountancy.years[j].workgroups[i].declarations.open[k]
                      .id === transaction.id
                  ) {
                    this.accountancy.years[j].workgroups[
                      i
                    ].declarations.open.splice(k, 1);
                  }
                }
                this.accountancy.years[j].workgroups[
                  i
                ].declarations.closed.push(transaction);
              }
            }
          }
        }
        this.$store.dispatch("saveAccountancy");
        this.$store.dispatch("sendTransactionDecision", {
          transaction: transaction,
          year: year,
          wg: wg,
        });
      } catch (error) {
        console.log(error.message);
      }
    },
    declineTransactionF(transaction, wg, year) {
      transaction.reason = this.declineReason;
      try {
        for (let j = 0; j < this.accountancy.years.length; j++) {
          if (this.accountancy.years[j].index == year) {
            for (
              let i = 0;
              i < this.accountancy.years[j].workgroups.length;
              i++
            ) {
              if (this.accountancy.years[j].workgroups[i].index === wg) {
                for (
                  let k = 0;
                  k <
                  this.accountancy.years[j].workgroups[i].declarations.open
                    .length;
                  k++
                ) {
                  if (
                    this.accountancy.years[j].workgroups[i].declarations.open[k]
                      .id === transaction.id
                  ) {
                    this.accountancy.years[j].workgroups[
                      i
                    ].declarations.open.splice(k, 1);
                  }
                }
                this.accountancy.years[j].workgroups[
                  i
                ].declarations.closed.push(transaction);
              }
            }
          }
        }
        this.$store.dispatch("saveAccountancy");
        this.$store.dispatch("sendTransactionDecision", {
          transaction: transaction,
          year: year,
          wg: wg,
        });
        this.declineReason = null;
        setTimeout(() => {
          this.declineTransactionDialog = false;
          this.buzzy = false;
        }, 2000);
      } catch (error) {
        console.log(error.message);
      }
    },
    downloadReceipt(id, file_type, wg) {
      let metadata;
      if (file_type === "doc" || file_type === "dot") {
        metadata = "application/msword";
      } else if (file_type === "docx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      } else if (
        file_type === "xls" ||
        file_type === "xlt" ||
        file_type === "xla"
      ) {
        metadata = "application/vnd.ms-excel";
      } else if (file_type === "xlsx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      } else if (
        file_type === "ppt" ||
        file_type === "pot" ||
        file_type === "pps" ||
        file_type === "ppa"
      ) {
        metadata = "application/vnd.ms-powerpoint";
      } else if (file_type === "pptx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      } else if (file_type === "mdb") {
        metadata = "application/vnd.ms-access";
      } else if (file_type === "pdf" || file_type === "PDF") {
        metadata = "application/pdf";
      } else if (file_type === "png") {
        metadata = "image/png";
      } else if (
        file_type === "jpg" ||
        file_type === "jpeg" ||
        file_type === "JPG" ||
        file_type === "JPEG"
      ) {
        metadata = "image/jpeg";
      }

      let filename = `Factuur_C${wg}_${id}`;
      let file = firebase.storage().ref(`accountancy/C${wg}/${id}`);
      file.getDownloadURL().then((url) => {
        Axios.get(url, { responseType: "blob" })
          .then(({ data }) => {
            const blob = new Blob([data], {
              type: metadata,
            });

            const url = document.createElement("a");
            url.href = URL.createObjectURL(blob);
            url.download = filename;
            url.click();
            URL.revokeObjectURL(url.href);
          })
          .catch((error) => alert(error));
      });
    },
  },
  beforeCreate() {
    this.$store.dispatch("loadSecretary");
    this.$store.dispatch("loadAccountancy");
  },
};
</script>

<style>
</style>