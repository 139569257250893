<template>
  <v-container>
    <v-layout row wrap xs12 class="ma-2 justify-space-between">
      <h1>
        <span class="ahcRed">Product overzicht</span>
      </h1>
      <v-spacer></v-spacer>
      <v-icon @click="toNewProduct" size="50" color="#cc212f">mdi-plus</v-icon>
    </v-layout>
    <v-card class="my-3">
      <v-system-bar class="pa-4" color="#ebb63b">
        <span class="ahcRed subheading"> </span>
      </v-system-bar>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Zoeken"
        class="ma-5"
        single-line
        hide-details
      ></v-text-field>
      <v-data-table
        :headers="productHeaders"
        :items="products"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        :search="search"
        item-key="id"
        show-expand
        hide-default-footer
        disable-pagination
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-alert class="my-2" outlined color="#ebb63b">
              <v-card v-if="!editProductDiv" flat>
                <v-row class="d-flex justify-space-around ma-3">
                  <v-col>
                    <span
                      >Naam: <b>{{ item.title }}</b></span
                    >
                  </v-col>
                  <v-col>
                    <span
                      >Beschikbaar in Webshop: <b>{{ item.webshop }}</b></span
                    >
                  </v-col>
                  <v-col class="d-flex justify-end">
                    <v-icon @click="editProductDiv = true">mdi-cog</v-icon>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-space-around ma-3">
                  <v-col>
                    <span
                      >Prijs: <b>€{{ item.price }}</b></span
                    >
                  </v-col>
                  <v-col>
                    <span
                      >Bezorgkosten: <b>€{{ item.deliveryPrice }}</b></span
                    >
                  </v-col>
                  <v-col>
                    <span></span>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-space-around my-3">
                  <v-col>
                    <v-flex class="d-flex justify-center" xs12 v-if="item.foto">
                      <img :src="images[item.id]" contain class="img-circle" />
                    </v-flex>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card class="mx-3" flat>
                      <h4 class="mx-3">Overzicht voorraad</h4>
                      <v-chip
                        color="#ebb63b"
                        text-color="#cc212f"
                        class="ma-3"
                        v-for="(loc, m) in stock"
                        :key="m"
                      >
                        <b
                          >{{ loc.title }}:
                          {{ getStockAmount(loc.title, item.id) }}</b
                        >

                        <v-icon
                          @click="
                            (tuneStockDialog = true),
                              (editStock.fromTitle = loc.title),
                              (editStock.itemTitle = item.title),
                              (editStock.itemID = item.id)
                          "
                          class="ml-4"
                          >mdi-tune-variant</v-icon
                        >
                      </v-chip>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-space-around my-3">
                  <v-col>
                    <v-expansion-panels flat>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <h3>Logboek</h3>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <span
                            v-for="(log, l) in item.changelog.slice().reverse()"
                            :key="l"
                            ><i>{{ log }}</i
                            ><br
                          /></span>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>
              </v-card>
              <v-card v-if="editProductDiv" flat>
                <v-row class="d-flex justify-space-around ma-3">
                  <v-col>
                    <v-text-field label="Naam" v-model="item.title"
                      >Naam: <b>{{ item.title }}</b></v-text-field
                    >
                  </v-col>
                  <v-col>
                    <v-radio-group
                      v-model="item.webshop"
                      label="Beschikbaar in webshop? (Verplicht)"
                    >
                      <v-radio
                        label="Zichtbaar in webshop"
                        :value="true"
                      ></v-radio>
                      <v-radio
                        label="Niet zichtbaar in webshop"
                        :value="false"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col class="d-flex justify-end"> </v-col>
                </v-row>
                <v-row class="d-flex justify-space-around ma-3">
                  <v-col>
                    <v-text-field
                      v-model="item.price"
                      outlined
                      label="Verkoops prijs (Verplicht)"
                      type="number"
                      prefix="€"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="item.deliveryPrice"
                      outlined
                      label="Bezorg kosten (Verplicht)"
                      type="number"
                      prefix="€"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <span></span>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-space-around my-3">
                  <v-col>
                    <v-flex class="d-flex justify-center" xs12 v-if="item.foto">
                      <img :src="images[item.id]" contain class="img-circle" />
                    </v-flex>

                    <v-btn text v-if="item.foto" @click="deleteImage(item.id)"
                      ><v-icon>mdi-close-circle-outline</v-icon>Foto
                      verwijderen</v-btn
                    >
                    <v-file-input
                      v-if="!item.foto"
                      accept="image/png, image/jpeg, image/bmp"
                      v-model="newImage"
                      prepend-icon="mdi-camera"
                      label="Afbeelding"
                    ></v-file-input>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-space-around ma-3">
                  <v-col>
                    <v-btn text @click="editProductInfo(item.id)"
                      ><span class="ahcRed">Opslaan</span></v-btn
                    >
                  </v-col>
                  <v-col>
                    <span></span>
                  </v-col>
                  <v-col>
                    <span></span>
                  </v-col>
                </v-row>
              </v-card>
            </v-alert>
          </td>
        </template>
        <template v-slot:[`item.webshop`]="{ item }">
          <v-icon :color="getColor(item.webshop)" dark> mdi-circle </v-icon>
        </template>
        <template v-slot:[`item.price`]="{ item }">
          <span>€{{ item.price }}</span>
        </template>
        <template v-slot:[`item.deliveryPrice`]="{ item }">
          <span>€{{ item.deliveryPrice }}</span>
        </template>
        <template v-slot:[`item.stockIntern`]="{ item }">
          <span>{{ getInternalStock(item) }}</span>
        </template>
        <template v-slot:[`item.stockExtern`]="{ item }">
          <span>{{ getExternalStock(item) }}</span>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="tuneStockDialog"
    >
      <v-card v-if="!buzzy">
        <v-system-bar class="pa-4" color="#ebb63b">
          <span class="ahcRed subheading"
            >Voorraad van {{ editStock.itemTitle }} op locatie
            {{ editStock.fromTitle }} aanpassen
          </span>
        </v-system-bar>
        <span class="ma-5"></span>
        <v-slider
          v-model="editStock.amount"
          class="ma-3"
          thumb-label="always"
          color="#ebb63b"
          label="Hoeveel?"
          min="1"
          :max="getMaxAmount(editStock.fromTitle, editStock.itemID)"
        ></v-slider>
        <v-select
          :items="['Verplaatst', 'Verkocht', 'Relatiegeschenk', 'Kapot']"
          label="Actie"
          class="ma-3"
          v-model="editStock.action"
        ></v-select>
        <v-select
          v-if="editStock.action === 'Verplaatst'"
          :items="stockTitles"
          label="Naar"
          class="ma-3"
          v-model="editStock.toTitle"
        ></v-select>
        <v-text-field
          v-if="editStock.action === 'Verkocht'"
          number
          label="Voor welk bedrag (per stuk)?"
          v-model="editStock.price"
        ></v-text-field>
        <v-text-field
          v-if="
            editStock.action === ('Verkocht' || 'Relatiegeschenk' || 'Kapot')
          "
          label="Beschrijving"
          v-model="editStock.note"
        ></v-text-field>
        <span class="ma-5"
          >TODO: 'Verkoop', 'relatiegeschenk' en 'kapot' wordt nog niet
          verwerkt</span
        >
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="(tuneStockDialog = false), (editStock = {})"
            ><span class="ahcRed">Annuleren</span></v-btn
          >

          <v-btn
            v-if="
              (editStock.action == 'Verplaatst' && editStock.toTitle) ||
              (editStock.action == 'Verkocht' && editStock.price) ||
              editStock.action ==
                ('Relatiegeschenk' || editStock.action == 'Kapot')
            "
            text
            @click="tuneStock"
            ><span class="ahcRed">Aanpassen</span></v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="ma-5" elevation="0" v-if="buzzy">
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular
            class="ma-5 ahcRed"
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Aanpassen voorraad...</h3>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import router from "../../router/index";
import firebase from "firebase";
export default {
  data() {
    return {
      view: "overview",
      search: "",
      productHeaders: [
        { text: "Naam", value: "title", align: "start" },
        { text: "Prijs", value: "price" },
        { text: "Bezorgprijs", value: "deliveryPrice" },
        { text: "Webshop?", value: "webshop" },
        { text: "Voorraad intern", value: "stockIntern" },
        { text: "Voorraad extern", value: "stockExtern" },
        { text: "", value: "data-table-expand" },
      ],
      editProductDiv: false,
      expanded: [],
      singleExpand: true,
      tuneStockDialog: false,
      editStock: {},
      buzzy: false,
      newImage: null,
    };
  },
  computed: {
    products() {
      let products = [];
      if (this.$store.state.webshop) {
        products = this.$store.state.webshop.products;
      }
      return products;
    },
    stock() {
      let stock = [];
      if (this.$store.state.webshop !== null) {
        stock = this.$store.state.webshop.stock;
      }
      return stock;
    },
    stockTitles() {
      let stockTitles = [];
      if (this.$store.state.webshop !== null) {
        this.$store.state.webshop.stock.forEach((x) => {
          stockTitles.push(x.title);
        });
      }
      return stockTitles;
    },
    userName() {
      return this.$store.state.currentUser.name;
    },
    images() {
      return this.$store.state.productImages;
    },
  },
  methods: {
    toNewProduct() {
      router.push({ name: "AddNewProduct" });
    },
    getColor(webshop) {
      if (webshop) {
        return "green";
      } else {
        return "red";
      }
    },
    getStockAmount(loc, prod) {
      let amount = 0;
      this.stock.forEach((location) => {
        if (location.title == loc) {
          location.products.forEach((item) => {
            if (item.id == prod) {
              amount = item.amount;
            }
          });
        }
      });
      return amount;
    },
    getInternalStock(product) {
      let amount = 0;
      this.stock.forEach((x) => {
        if (x.internal) {
          x.products.forEach((y) => {
            if (y.id === product.id) {
              amount += y.amount;
            }
          });
        }
      });
      return amount;
    },
    getExternalStock(product) {
      let amount = 0;
      this.stock.forEach((x) => {
        if (!x.internal) {
          x.products.forEach((y) => {
            if (y.id === product.id) {
              amount += y.amount;
            }
          });
        }
      });
      return amount;
    },
    getMaxAmount(stockTitle, itemID) {
      let amount = 0;
      this.stock.forEach((x) => {
        if (x.title == stockTitle) {
          x.products.forEach((y) => {
            if (y.id == itemID) {
              amount = y.amount;
            }
          });
        }
      });
      return amount;
    },
    tuneStock() {
      this.buzzy = true;
      let creationDate = new Date();
      let dd = String(creationDate.getDate()).padStart(2, "0");
      let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = creationDate.getFullYear();
      creationDate = dd + "/" + mm + "/" + yyyy;
      if (this.editStock.action == "Verplaatst") {
        this.stock.forEach((x) => {
          if (x.title == this.editStock.fromTitle) {
            x.products.forEach((y) => {
              if (y.id == this.editStock.itemID) {
                y.amount -= this.editStock.amount;
              }
            });
          } else if (x.title == this.editStock.toTitle) {
            x.products.forEach((y) => {
              if (y.id == this.editStock.itemID) {
                y.amount += this.editStock.amount;
              }
            });
          }
        });
        let msg = `${this.userName} heeft ${this.editStock.amount} stuks verplaatst van ${this.editStock.fromTitle} naar ${this.editStock.toTitle} op ${creationDate}`;
        this.products.forEach((x) => {
          if (x.id === this.editStock.itemID) {
            x.changelog.push(msg);
          }
        });
      }
      this.$store.dispatch("saveWebshop");
      this.buzzy = false;
      this.editStock = {};
      this.tuneStockDialog = false;
    },
    editProductInfo(id) {
      let creationDate = new Date();
      let dd = String(creationDate.getDate()).padStart(2, "0");
      let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = creationDate.getFullYear();
      creationDate = dd + "/" + mm + "/" + yyyy;
      let msg = `${this.userName} heeft het product aangepast op ${creationDate}`;
      if (this.newImage) {
        this.uploadImage(id);
      }
      this.products.forEach((x) => {
        if (x.id == id) {
          x.price = parseFloat(parseFloat(x.price).toFixed(2));
          x.deliveryPrice = parseFloat(parseFloat(x.deliveryPrice).toFixed(2));
          x.changelog.push(msg);
        }
      });
      this.$store.dispatch("saveWebshop");
      this.editProductDiv = false;
    },
    uploadImage(id) {
      let file = this.newImage;
      let file_type = file.type;
      if (file_type && file_type.substr(0, 5) == "image") {
        let img = document.createElement("img");
        let reader = new FileReader();
        reader.onload = function (e) {
          img.src = e.target.result;
          img.onload = function () {
            let MAX_WIDTH = 400;
            let width = img.naturalWidth;
            let height = img.naturalHeight;
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
            let canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);
            canvas.toBlob((blob) => {
              let storageRef = firebase.storage().ref(`images/products/${id}`);
              storageRef.put(blob);
            });
          };
        };
        reader.readAsDataURL(file);
      }
      this.products.forEach((x) => {
        if (x.id == id) {
          x.foto = true;
        }
      });
      this.$store.dispatch("saveWebshop");
      setTimeout(() => this.$store.dispatch("loadProductImages"), 3000);

      this.newImage = null;
    },
    deleteImage(id) {
      let deleteRef = firebase.storage().ref(`images/products/${id}`);
      deleteRef.delete();
      this.products.forEach((x) => {
        if (x.id == id) {
          x.foto = false;
        }
      });
      this.$store.dispatch("saveWebshop");
    },
  },
  beforeCreate() {
    if (this.$store.state.webshop === null) {
      this.$store.dispatch("loadWebshop");
      this.$store.dispatch("loadProductImages");
    }
  },
};
</script>

<style>
</style>