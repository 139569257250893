<template>
  <v-container>
    <v-layout v-if="commissionContent" row wrap xs12 class="ma-2 justify-space-between">
      <h1 class="ma-4 d-flex">
        <span class="ahcRed"
          >Commissie {{ commissionContent.index }} - {{ commissionContent.name }}</span
        >
      </h1>
    </v-layout>
    <v-layout row wrap class="ma-3" name="layoutInfoModules">
      <v-flex xs12 md5 name="flexMembersPurposeDocs">
        <v-flex class="ma-2" xs12 name="flexMembers">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Leden</b>
              </span>
            </v-system-bar>
            <v-card-text>
              <span
                ><b>{{ preces }}</b
                ><br
              /></span>
              <span v-for="member in commissionMembers" :key="member"
                >{{ member }}<br />
              </span>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-card class="ma-5" elevation="0" v-if="!commissionContent">
          <v-row class="d-flex justify-space-around mb-6">
            <v-progress-circular
              class="ma-5 ahcRed"
              :size="70"
              :width="7"
              indeterminate
            ></v-progress-circular>
          </v-row>
          <v-row class="d-flex justify-space-around mb-6">
            <h3>Inladen commissiedocumenten...</h3>
          </v-row>
        </v-card>
  
        <v-flex v-if="commissionContent" class="ma-2" xs12 name="flexScript">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Draaiboek en begroting</b>
              </span>
              <v-spacer></v-spacer>
              <div class="d-flex" v-if="isPreces && !editWorkgroupScript">
                <v-icon color="#cc212f" @click="editWorkgroupScript = true"
                  >mdi-file-plus-outline</v-icon
                >
              </div>
            </v-system-bar>
            <v-card-text
              v-if="!editWorkgroupScript && commissionContent.scripts.length == 0"
            >
              <span>
                Er is voor deze commissie nog geen draaiboek geupload. Het is de
                taak van de voorzitter om dit te verzorgen.
              </span>
            </v-card-text>

            <v-form class="pa-2" v-if="editWorkgroupScript">
              <h4>Nieuw draaiboek of begroting uploaden</h4>
              <v-text-field
                label="Titel (verplicht)"
                v-model="newScript.title"
              ></v-text-field>
              <v-textarea
                label="Beschrijving (optioneel)"
                v-model="newScript.description"
              >
              </v-textarea>
              <v-radio-group v-model="editWorkgroupScriptRadiogroup">
                <v-radio :value="0" label="Document (word, excel, ppx, etc..)"></v-radio>
                <v-radio :value="1"
                  label="Link (Google doc, Google spreadsheet, etc..)"
                ></v-radio>
              </v-radio-group>
              <v-file-input
                v-if="editWorkgroupScriptRadiogroup == 0"
                label="te uploaden document: "
                v-model="newScript.doc"
                accept=".pdf,.doc,.docx,.xml,.xls,.xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ></v-file-input>
              <v-text-field
                v-if="editWorkgroupScriptRadiogroup == 1"
                label="link: "
                v-model="newScript.link"
              ></v-text-field>
              <v-btn @click="(editWorkgroupScript = false), (newScript = {})"
                >Annuleren</v-btn
              >
              <v-btn
                v-if="newScript.title && (newScript.doc || newScript.link)"
                @click="saveWorkgroupScript()"
                >Opslaan</v-btn
              >
            </v-form>
            <v-list
              v-if="commissionContent.scripts.length > 0 && !editWorkgroupScript"
              three-line
            >
              <v-list-item
                v-for="(script, i) in commissionContent.scripts"
                :key="i"
              >
                <v-list-item-icon v-if="script.link">
                  <a :href="'//' + script.link" target="_blank">
                    <v-icon>mdi-open-in-new </v-icon>
                  </a>
                </v-list-item-icon>
                <v-list-item-icon
                  v-if="script.doc_id"
                  @click="
                    downloadFile(script.title, script.doc_id, script.type, script.commissionId)
                  "
                >
                  <v-icon>mdi-file-download-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ script.title }}</v-list-item-title>
                  <v-list-item-subtitle v-if="script.description">{{
                    script.description
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle
                    ><small
                      >{{ script.creationDate }} - {{ script.creator }}</small
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-icon
                  v-if="isPreces"
                  @click="
                    (deleteScriptDialog = true), (deleteScript = {id: script.id, commissionId: script.commissionId})
                  "
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-card>
        </v-flex>
        <v-flex v-if="commissionContent" class="ma-2" xs12 name="flexDocs">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Documenten</b>
              </span>
              <v-spacer></v-spacer>
              <div class="d-flex" v-if="!editWorkgroupDoc">
                <v-icon color="#cc212f" @click="editWorkgroupDoc = true"
                  >mdi-file-plus-outline</v-icon
                >
              </div>
            </v-system-bar>
            <v-card-text
              v-if="!editWorkgroupDoc && commissionContent.docs.length == 0"
            >
              <span>
                Er zijn voor deze commissie nog geen documenten geupload. Ieder
                commissielid kan hier documenten of google drive links opslaan.
              </span>
            </v-card-text>

            <v-form class="pa-2" v-if="editWorkgroupDoc">
              <h4>Nieuw document uploaden</h4>
              <v-text-field
                label="Titel (verplicht)"
                v-model="newDoc.title"
              ></v-text-field>
              <v-textarea
                label="Beschrijving (optioneel)"
                v-model="newDoc.description"
              >
              </v-textarea>
              <v-radio-group v-model="editWorkgroupDocRadiogroup">
                <v-radio :value="0" label="Document (word, excel, ppx, etc..)"></v-radio>
                <v-radio :value="1"
                  label="Link (Google doc, Google spreadsheet, etc..)"
                ></v-radio>
              </v-radio-group>
              <v-file-input
                v-if="editWorkgroupDocRadiogroup == 0"
                label="te uploaden document: "
                v-model="newDoc.doc"
                accept=".pdf,.doc,.docx,.xml,.xls,.xlsx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ></v-file-input>
              <v-text-field
                v-if="editWorkgroupDocRadiogroup == 1"
                label="link: "
                v-model="newDoc.link"
              ></v-text-field>
              <v-btn @click="(editWorkgroupDoc = false), (newDoc = {})"
                >Annuleren</v-btn
              >
              <v-btn
                v-if="newDoc.title && (newDoc.doc || newDoc.link)"
                @click="saveWorkgroupDoc()"
                >Opslaan</v-btn
              >
            </v-form>
            <v-list
              v-if="commissionContent.docs.length > 0 && !editWorkgroupDoc"
              three-line
            >
              <v-list-item v-for="(doc, i) in commissionContent.docs" :key="i">
                <v-list-item-icon v-if="doc.link">
                  <a :href="'//' + doc.link" target="_blank">
                    <v-icon>mdi-open-in-new </v-icon>
                  </a>
                </v-list-item-icon>
                <v-list-item-icon
                  v-if="doc.doc_id"
                  @click="downloadFile(doc.title, doc.doc_id, doc.type, doc.commissionId)"
                >
                  <v-icon>mdi-file-download-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ doc.title }}</v-list-item-title>
                  <v-list-item-subtitle v-if="doc.description">{{
                    doc.description
                  }}</v-list-item-subtitle>
                  <v-list-item-subtitle
                    ><small
                      >{{ doc.creationDate }} - {{ doc.creator }}</small
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-icon
                  @click="(deleteDocDialog = true), (deleteDoc = {id: doc.id, commissionId: doc.commissionId})"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-card>
        </v-flex>
      </v-flex>
       <v-flex xs12 name="flexAccountancy" v-if="accountancy">
        <v-flex class="ma-2" xs12 name="flexAccountancy">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span class="ahcRed subheading">
                <b>Boekhouding</b>
              </span>
            </v-system-bar>
            <v-tabs color="#cc212f">
              <v-tab
                v-model="yearTab"
                v-for="(year, i) in accountancy.years"
                :key="i"
                >{{ year.title }}</v-tab
              >
              <v-tab-item v-for="(year, i) in accountancy.years" :key="i">
                <v-card-text>
                  <div class="my-3" v-if="year.locked">
                    <v-btn color="#ebb63b" disabled>
                      <div class="d-flex mr-4">
                        <v-icon size="20" color="#cc212f"
                          >mdi-text-box-plus-outline</v-icon
                        >
                      </div>
                      <span class="grey--text">Declaratie indienen</span>
                    </v-btn>
                    <h4 class="grey--text">
                      <i
                        >Het indienen van declaraties is geblokkeerd door de
                        penningmeester voor dit boekjaar.</i
                      >
                    </h4>
                  </div>
                  <div class="my-3" v-if="!year.locked">
                    <v-btn
                      color="#ebb63b"
                      @click="
                        (addDeclarationDialog = true), (yearIndex = year.index)
                      "
                    >
                      <div class="d-flex mr-4">
                        <v-icon size="20" color="#cc212f"
                          >mdi-text-box-plus-outline</v-icon
                        >
                      </div>
                      <span class="ahcRed">Declaratie indienen</span>
                    </v-btn>
                  </div>
                  <v-alert outlined color="#ebb63b">
                    <v-flex xs12 class="ma-2">
                      <v-row>
                        <v-col>
                          <v-card></v-card>
                        </v-col>
                        <v-col>
                          <v-card flat><h3>Uitgaven</h3></v-card>
                        </v-col>
                        <v-col>
                          <v-card flat><h3>Inkomsten</h3></v-card>
                        </v-col>
                        <v-col>
                          <v-card flat><h3>Resultaat</h3></v-card>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-card flat><h3>Begroot</h3></v-card>
                        </v-col>
                        <v-col>
                          <v-card flat
                            ><v-chip color="#ebb63b" text-color="#cc212f"
                              >€{{
                                getTotalValue(year, "expencesBudget").toFixed(2)
                              }}</v-chip
                            ></v-card
                          >
                        </v-col>
                        <v-col>
                          <v-card flat
                            ><v-chip color="#ebb63b" text-color="#cc212f"
                              >€{{
                                getTotalValue(year, "incomeBudget").toFixed(2)
                              }}</v-chip
                            ></v-card
                          >
                        </v-col>
                        <v-col>
                          <v-card flat
                            ><v-chip color="#ebb63b" text-color="#cc212f"
                              >€{{
                                (
                                  getTotalValue(year, "incomeBudget")
                                   -
                                  getTotalValue(year, "expencesBudget")
                                ).toFixed(2)
                              }}</v-chip
                            ></v-card
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-card flat
                            ><h3>Werkelijk</h3>
                            <small>(Tot op heden)</small></v-card
                          >
                        </v-col>
                        <v-col>
                          <v-card flat
                            ><v-chip color="#ebb63b" text-color="#cc212f"
                              >€{{
                                getTotalValue(year, "expences").toFixed(2)
                              }}</v-chip
                            ></v-card
                          >
                        </v-col>
                        <v-col>
                          <v-card flat
                            ><v-chip color="#ebb63b" text-color="#cc212f"
                              >€{{
                                getTotalValue(year, "income").toFixed(2)
                              }}</v-chip
                            ></v-card
                          >
                        </v-col>
                        <v-col>
                          <v-card flat
                            ><v-chip color="#ebb63b" text-color="#cc212f"
                              >€{{
                                (
                                  getTotalValue(year, "income")
                                   -
                                  getTotalValue(year, "expences")
                                ).toFixed(2)
                              }}</v-chip
                            ></v-card
                          >
                        </v-col>
                      </v-row>
                    </v-flex>
                  </v-alert>
                <!--  <v-card class="my-2">
                    <v-system-bar class="pa-4">
                      <span>
                        <b>Transacties</b>
                      </span>
                    </v-system-bar>
                    <v-expansion-panels flat>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <span><b>Openstaande declaraties</b></span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-list
                            v-if="
                              year.workgroups[$route.params.index - 1]
                                .declarations.open.length > 0
                            "
                            two
                            line
                          >
                            <v-list-item
                              v-for="(transaction, k) in year.workgroups[
                                $route.params.index - 1
                              ].declarations.open"
                              :key="k"
                            >
                              <v-list-item-icon v-if="transaction.amount < 0"
                                ><v-icon color="red"
                                  >mdi-arrow-down-bold</v-icon
                                >
                              </v-list-item-icon>
                              <v-list-item-icon v-if="transaction.amount > 0"
                                ><v-icon color="green"
                                  >mdi-arrow-up-bold</v-icon
                                ></v-list-item-icon
                              >

                              <v-list-item-content>
                                <v-list-item-title
                                  >€{{ transaction.amount.toFixed(2) }}
                                  {{
                                    transaction.description
                                  }}</v-list-item-title
                                >

                                <v-list-item-subtitle
                                  ><small
                                    >{{ transaction.creationDate }} -
                                    {{ transaction.creator }}</small
                                  ></v-list-item-subtitle
                                >
                              </v-list-item-content>

                              <v-list-item-icon
                                v-if="transaction.receiptType"
                                @click="
                                  downloadReceipt(
                                    transaction.id,
                                    transaction.receiptType
                                  )
                                "
                              >
                                <v-icon>mdi-file-download-outline</v-icon>
                              </v-list-item-icon>
                            </v-list-item>
                          </v-list>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <span><b>Afgesloten declaraties</b></span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-list
                            v-if="
                              year.workgroups[$route.params.index - 1]
                                .declarations.closed.length > 0
                            "
                            three
                            line
                          >
                            <v-list-item
                              v-for="(transaction, k) in year.workgroups[
                                $route.params.index - 1
                              ].declarations.closed"
                              :key="k"
                            >
                              <v-list-item-icon
                                v-if="
                                  transaction.amount < 0 && !transaction.reason
                                "
                                ><v-icon color="red"
                                  >mdi-arrow-down-bold</v-icon
                                >
                              </v-list-item-icon>
                              <v-list-item-icon
                                v-if="
                                  transaction.amount > 0 && !transaction.reason
                                "
                                ><v-icon color="green"
                                  >mdi-arrow-up-bold</v-icon
                                ></v-list-item-icon
                              >
                              <v-list-item-icon v-if="transaction.reason"
                                ><v-icon color="red"
                                  >mdi-close-thick</v-icon
                                ></v-list-item-icon
                              >
                              <v-list-item-content>
                                <v-list-item-title
                                  >€{{ transaction.amount.toFixed(2) }}
                                  {{
                                    transaction.description
                                  }}</v-list-item-title
                                >
                                <v-list-item-subtitle v-if="transaction.reason"
                                  ><small
                                    >{{ transaction.reason }}
                                  </small></v-list-item-subtitle
                                >
                                <v-list-item-subtitle
                                  ><small
                                    >{{ transaction.creationDate }} -
                                    {{ transaction.creator }}</small
                                  ></v-list-item-subtitle
                                >
                              </v-list-item-content>
                              <v-list-item-icon
                                v-if="transaction.receiptType"
                                @click="
                                  downloadReceipt(
                                    transaction.id,
                                    transaction.receiptType
                                  )
                                "
                              >
                                <v-icon>mdi-file-download-outline</v-icon>
                              </v-list-item-icon>
                            </v-list-item>
                          </v-list>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card> -->
                </v-card-text>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-flex>
      </v-flex> 
    </v-layout>

    <v-dialog
      transition="dialog-top-transition"
      max-width="300"
      v-model="deleteScriptDialog"
    >
      <v-card v-if="!buzzy">
        <v-card-title class="headerClass"
          >Draaiboek/begroting verwijderen?</v-card-title
        >
        <v-card-text>Deze actie is onomkeerbaar</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="(deleteScriptDialog = false), (deleteScript = null)"
            >Annuleren</v-btn
          >

          <v-btn
            color="green darken-1"
            text
            @click="deleteScriptF(deleteScript.id, deleteScript.commissionId)"
            >Verwijderen</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="ma-5" elevation="0" v-if="buzzy">
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular
            class="ma-5 ahcRed"
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Verwijderen document</h3>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-top-transition"
      max-width="300"
      v-model="deleteDocDialog"
    >
      <v-card v-if="!buzzy">
        <v-card-title class="headerClass">Document verwijderen?</v-card-title>
        <v-card-text
          >Deze actie is onomkeerbaar, doe dit altijd in overleg met je
          commissieleden</v-card-text
        >

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="(deleteDocDialog = false), (deleteDoc = null)"
            >Annuleren</v-btn
          >

          <v-btn color="green darken-1" text @click="deleteDocF(deleteDoc.id, deleteDoc.commissionId)"
            >Verwijderen</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card class="ma-5" elevation="0" v-if="buzzy">
        <v-row class="d-flex justify-space-around mb-6">
          <v-progress-circular
            class="ma-5 ahcRed"
            :size="70"
            :width="7"
            indeterminate
          ></v-progress-circular>
        </v-row>
        <v-row class="d-flex justify-space-around mb-6">
          <h3>Verwijderen document</h3>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-top-transition"
      max-width="900"
      v-model="addDeclarationDialog"
    >
      <v-card>
        <v-toolbar color="#ebb63b" class="text--white">
          <span>Declaratie formulies</span>
          <v-spacer></v-spacer>
          <v-icon
            @click="
              (declaration = {}),
                (addDeclarationDialog = false),
                (declarationStepper = 1),
                (yearIndex = null)
            "
            >mdi-close-outline</v-icon
          >
        </v-toolbar>
        <v-stepper v-model="declarationStepper" vertical v-if="declaration">
          <!-- Description -->
          <v-stepper-step
            color="#ebb63b"
            :complete="declarationStepper > 1"
            step="1"
            >Korte beschrijving:</v-stepper-step
          >

          <v-stepper-content step="1">
            <v-text-field
              label="Beschrijving"
              v-model="declaration.description"
            ></v-text-field>
            <v-btn
              v-if="declaration.description"
              color="#ebb63b"
              @click="declarationStepper = 2"
              >Volgende</v-btn
            >
          </v-stepper-content>

          <!-- Amount -->
          <v-stepper-step
            color="#ebb63b"
            :complete="declarationStepper > 2"
            step="2"
            >Bedrag:
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-text-field
              v-model="declaration.amount"
              label="Bedrag"
              type="number"
              >€</v-text-field
            >
            <v-btn
              v-if="declaration.amount"
              color="#ebb63b"
              @click="declarationStepper = 3"
              >Volgende</v-btn
            >
            <v-btn text @click="declarationStepper -= 1">Terug</v-btn>
          </v-stepper-content>

          <!-- directPay -->
          <v-stepper-step
            :complete="declarationStepper > 3"
            step="3"
            color="#ebb63b"
          >
            Te betalen aan:
          </v-stepper-step>

          <v-stepper-content step="3">
            <v-radio-group
              v-model="declaration.directPay"
              color="#cc212f"
              light
            >
              <v-radio :value="0" label="Rechtstreeks aan de leverancier."></v-radio>
              <v-radio :value="1" label="Aan mij."></v-radio>
            </v-radio-group>
            <div v-if="declaration.directPay == 1">
              <v-switch
                label="Heb je een nieuw rekening nummer?"
                v-model="declaration.newAccountnumber"
                column
                justify="center"
              ></v-switch>
            </div>
            <v-text-field
              v-model="declaration.accountnumber"
              label="Rekeningnummer"
              v-if="declaration.newAccountnumber"
            >
            </v-text-field>
            <v-btn
              color="#ebb63b"
              @click="declarationStepper = 4"
              v-if="
                declaration.directPay == 0 ||
                !declaration.newAccountnumber ||
                declaration.accountnumber
              "
              >Volgende</v-btn
            >
            <v-btn text @click="declarationStepper -= 1">Terug</v-btn>
          </v-stepper-content>

          <!-- Add receipt? -->
          <v-stepper-step
            color="#ebb63b"
            :complete="declarationStepper > 4"
            step="4"
            >Factuur toevoegen</v-stepper-step
          >
          <v-stepper-content step="4">
            <v-file-input
              v-model="declaration.receipt"
              prepend-icon="mdi-camera"
              label="factuur"
            ></v-file-input>

            <v-btn
              v-if="declaration.receipt"
              color="#ebb63b"
              @click="addDeclaration()"
              >Declaratie indienen</v-btn
            >
            <v-btn text @click="declarationStepper -= 1">Terug</v-btn>
          </v-stepper-content>
        </v-stepper>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import firebase from "firebase";
import Axios from "axios";
export default {
  data() {
    return {
      editGoal: false,
      editActiveContent: false,
      editWorkgroupScript: false,
      editWorkgroupScriptRadiogroup: 0,
      newScript: {},
      deleteScript: null,
      deleteScriptDialog: false,
      editWorkgroupDoc: false,
      editWorkgroupDocRadiogroup: 0,
      newDoc: {},
      deleteDoc: null,
      deleteDocDialog: false,
      buzzy: false,
      changeGoal: false,
      yearTab: 0,
      addDeclarationDialog: false,
      declaration: {},
      declarationStepper: 1,
      yearIndex: null,
      editCalendar: false,
      changeCalendar: false,
      newCalendarDate: {},
      //datePicker: false,
      calendarHeaders: [
        {
          text: "Datum",
          align: "start",
          sortable: false,
          value: "date",
        },
        {
          text: "Activiteit",
          sortable: false,
          value: "name",
        },
        {
          text: "Opmerkingen",
          sortable: false,
          value: "comment",
        },
        { sortable: false, value: "actions" },
      ],
    };
  },
  computed: {
    
    commissionContent() {
      return this.$store.state.commissionStore.commissionContent;
    },
    isPreces() {
      return this.commissionContent?.preces === this.$store.state?.currentUser?.id;
    },
    
    userName() {
      return this.$store.state.currentUser.name;
    },
    accountancy() {
      return this.$store.state.accountancy;
    },
    users() {
      return this.$store.state.users;
    },
    commissionMembers() {
      let members = [];
      if(this.commissionContent.length === 0 || this.users.length === 0) return;
      this.users.forEach((user) => {
        if (this.commissionContent.members?.includes(user.id)) {
          members.push(user.name);
        }
      });
      return members;
    },
    preces() {
      let preces = "";
      if(this.commissionContent.length === 0 || this.users.length === 0) return;
      this.users.forEach((user) => {
        if (this.commissionContent.preces === user.id) {
          preces = user.name;
        }
      });
      return preces;
    },
  },
  methods: {
    getTotalValue(year, type){
      try {
        let total = 0;
        if(year.index < 5){
          
          this.commissionContent.oldCommissions.forEach((commission) => {
             year.workgroups.forEach((workgroup) => {
              if(commission === workgroup.index){
                total += parseFloat(workgroup[type]);
              }
          });
        });
          
        } else {
          let index = year.workgroups.findIndex((workgroup) => workgroup.id === this.commissionContent.id);
          total = parseFloat(year.workgroups[index][type]);
        }
        return total;
      } catch (error) {
        console.log(error.message);
      }
    },



    saveWorkgroupScript() {
      try {
        let id = Date.now();
        let creationDate = new Date();
        let dd = String(creationDate.getDate()).padStart(2, "0");
        let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = creationDate.getFullYear();
        creationDate = dd + "/" + mm + "/" + yyyy;
        let creator = this.userName;
        if (this.newScript.link) {
          this.newScript.link = this.newScript.link.replace("https://", "");
          this.newScript.id = id;
          this.newScript.commissionId = this.$route.params.id;
          this.newScript.creator = creator;
          this.newScript.creationDate = creationDate;
          this.$store.dispatch("commissionStore/saveCommissionScript", {
            commissionId: this.$route.params.id,
            script: this.newScript,
          });
          this.newScript = {};
          this.editWorkgroupScript = false;
        } else if (this.newScript.doc) {
          let script = {
            id: id,
            title: this.newScript.title,
            description: this.newScript.description,
            doc_id: id,
            commissionId: this.$route.params.id,
            creator: creator,
            creationDate: creationDate,
            type: this.newScript.doc.name.split(".").pop(),
          };

          let storageRef = firebase.storage().ref();
          storageRef
            .child(`docs/C${script.commissionId}/${id}`)
            .put(this.newScript.doc);
            this.$store.dispatch("commissionStore/saveCommissionScript", {
            commissionId: this.$route.params.id,
            script: script,
          });
          this.newScript = {};
          this.editWorkgroupScript = false;
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    saveWorkgroupDoc() {
      try {
        let id = Date.now();
        let creationDate = new Date();
        let dd = String(creationDate.getDate()).padStart(2, "0");
        let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = creationDate.getFullYear();
        creationDate = dd + "/" + mm + "/" + yyyy;
        let creator = this.userName;
        if (this.newDoc.link) {
          this.newDoc.link = this.newDoc.link.replace("https://", "");
          this.newDoc.id = id;
          this.newDoc.commissionId = this.$route.params.id;
          this.newDoc.creator = creator;
          this.newDoc.creationDate = creationDate;
          this.$store.dispatch("commissionStore/saveCommissionDoc", {
            commissionId: this.$route.params.id,
            doc: this.newDoc,
          });
          this.newDoc = {};
          this.editWorkgroupDoc = false;
        } else if (this.newDoc.doc) {
          let doc = {
            id: id,
            commissionId: this.$route.params.id,
            title: this.newDoc.title,
            description: this.newDoc.description,
            doc_id: id,
            creator: creator,
            creationDate: creationDate,
            type: this.newDoc.doc.name.split(".").pop(),
          };

          let storageRef = firebase.storage().ref();
          storageRef
            .child(`docs/C${doc.commissionId}/${id}`)
            .put(this.newDoc.doc);
            this.$store.dispatch("commissionStore/saveCommissionDoc", {
            commissionId: this.$route.params.id,
            doc: doc,
          });
          this.newDoc = {};
          this.editWorkgroupDoc = false;
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    downloadFile(title, id, file_type, commissionId) {
      let metadata;
      if (file_type === ("doc" || "dot")) {
        metadata = "application/msword";
      } else if (file_type === "docx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      } else if (file_type === ("xls" || "xlt" || "xla")) {
        metadata = "application/vnd.ms-excel";
      } else if (file_type === "xlsx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      } else if (file_type === ("ppt" || "pot" || "pps" || "ppa")) {
        metadata = "application/vnd.ms-powerpoint";
      } else if (file_type === "pptx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      } else if (file_type === "mdb") {
        metadata = "application/vnd.ms-access";
      } else if (file_type === "pdf") {
        metadata = "application/pdf";
      }

      let filename = title;
      let file = firebase
        .storage()
        .ref(`docs/C${commissionId}/${id}`);
      file.getDownloadURL().then((url) => {
        Axios.get(url, { responseType: "blob" })
          .then(({ data }) => {
            const blob = new Blob([data], {
              type: metadata,
            });

            const url = document.createElement("a");
            url.href = URL.createObjectURL(blob);
            url.download = filename;
            url.click();
            URL.revokeObjectURL(url.href);
          })
          .catch((error) => alert(error));
      });
    },
    deleteScriptF(id, commissionId) {
      this.buzzy = true;
      for (let i = 0; i < this.commissionContent.scripts.length; i++) {
        if (this.commissionContent.scripts[i].id === id) {
          if (this.commissionContent.scripts[i].doc_id) {
            let storageRef = firebase.storage().ref();
            storageRef
              .child(`docs/C${commissionId}/${id}`)
              .delete();
          }
          this.$store.dispatch("commissionStore/deleteCommissionScript", {
            commissionId: this.$route.params.id,
            scriptId: id,
          });
        }
      }
      this.deleteScript = null;
      setTimeout(() => {
        this.deleteScriptDialog = false;
        this.buzzy = false;
      }, 2000);
    },
    deleteDocF(id, commissionId) {
      this.buzzy = true;
      for (let i = 0; i < this.commissionContent.docs.length; i++) {
        if (this.commissionContent.docs[i].id === id) {
          if (this.commissionContent.docs[i].doc_id) {
            let storageRef = firebase.storage().ref();
            storageRef
              .child(`docs/C${commissionId}/${id}`)
              .delete();
          }
          this.$store.dispatch("commissionStore/deleteCommissionDoc", {
            commissionId: this.$route.params.id,
            docId: id,
          });
        }
      }
      this.deleteDoc = null;
      setTimeout(() => {
        this.deleteDocDialog = false;
        this.buzzy = false;
      }, 2000);
    },
    addDeclaration() {
      try {
        let id = Date.now();
        this.declaration.id = id;
        let params = this.$route.params.id;
        let creationDate = new Date();
        let dd = String(creationDate.getDate()).padStart(2, "0");
        let mm = String(creationDate.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyy = creationDate.getFullYear();
        let amount = 0;
        this.declaration.creationDate = dd + "/" + mm + "/" + yyyy;
        this.declaration.creator = this.userName;
        this.declaration.creatorMail = this.$store.state.currentUser.email;
        this.declaration.receiptType = this.declaration.receipt.name
          .split(".")
          .pop();
        this.declaration.amount = amount - this.declaration.amount;
        let storageRef = firebase.storage().ref();
        if (this.declaration.receipt.type.substr(0, 5) == "image") {
          let img = document.createElement("img");
          let reader = new FileReader();
          reader.onload = function (e) {
            img.src = e.target.result;
            img.onload = function () {
              let MAX_WIDTH = 1200;
              let width = img.naturalWidth;
              let height = img.naturalHeight;
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
              let canvas = document.createElement("canvas");
              canvas.width = width;
              canvas.height = height;
              let ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0, width, height);
              canvas.toBlob((blob) => {
                storageRef.child(`accountancy/C${params}/${id}`).put(blob);
              });
            };
          };
          reader.readAsDataURL(this.declaration.receipt);
        } else {
          storageRef
            .child(`accountancy/C${params}/${id}`)
            .put(this.declaration.receipt);
        }
        delete this.declaration.receipt;
        setTimeout(() => {
          this.$store.dispatch("addDeclaration", {
            year: this.yearIndex,
            wg: this.$route.params.index - 1,
            declaration: this.declaration,
            accountancy: this.accountancy,
            userEmail: this.$store.state.currentUser.email,
          });
          this.addDeclarationDialog = false;
          this.declaration = {};
          this.declarationStepper = 1;
          this.yearIndex = null;
          console.log("Declaratie ingediend!");
        }, 3000);
      } catch (error) {
        console.log(error.message);
      }
    },
    downloadReceipt(id, file_type) {
      let metadata;
      if (file_type === "doc" || file_type === "dot") {
        metadata = "application/msword";
      } else if (file_type === "docx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      } else if (
        file_type === "xls" ||
        file_type === "xlt" ||
        file_type === "xla"
      ) {
        metadata = "application/vnd.ms-excel";
      } else if (file_type === "xlsx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      } else if (
        file_type === "ppt" ||
        file_type === "pot" ||
        file_type === "pps" ||
        file_type === "ppa"
      ) {
        metadata = "application/vnd.ms-powerpoint";
      } else if (file_type === "pptx") {
        metadata =
          "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      } else if (file_type === "mdb") {
        metadata = "application/vnd.ms-access";
      } else if (file_type === "pdf") {
        metadata = "application/pdf";
      } else if (file_type === "png") {
        metadata = "image/png";
      } else if (
        file_type === "jpg" ||
        file_type === "jpeg" ||
        file_type === "JPG" ||
        file_type === "JPEG"
      ) {
        metadata = "image/jpeg";
      }

      let filename = `Factuur_C${this.$route.params.id}_${id}`;
      let file = firebase
        .storage()
        .ref(`accountancy/C${this.$route.params.id}/${id}`);
      file.getDownloadURL().then((url) => {
        Axios.get(url, { responseType: "blob" })
          .then(({ data }) => {
            const blob = new Blob([data], {
              type: metadata,
            });

            const url = document.createElement("a");
            url.href = URL.createObjectURL(blob);
            url.download = filename;
            url.click();
            URL.revokeObjectURL(url.href);
          })
          .catch((error) => alert(error));
      });
    },
  },

  beforeCreate() {
    this.$store.dispatch("commissionStore/loadCommissionContent", {id: this.$route.params.id});
    this.$store.dispatch("loadAccountancy");
  },
  beforeRouteLeave(to, from, next) {
    if (this.changeGoal) {
      const answer = window.confirm(
        "Weet je zeker dat je de pagina wilt verlaten zonder de wijzigingen op te slaan?"
      );
      if (answer) {
        this.change = false;
        next();
      } else {
        next(false);
      }
    } else if (this.changeCalendar) {
      const answer = window.confirm(
        "Weet je zeker dat je de pagina wilt verlaten zonder de wijzigingen in de kalender op te slaan?"
      );
      if (answer) {
        this.change = false;
        next();
      } else {
        next(false);
      }
    } else {
      this.$store.dispatch("commissionStore/clearCommissionContent");
      next();
    }
  },
};
</script>

<style>
</style>