<template>
  <v-container>
    <h1 class="ma-4 d-flex">
      <span class="ahcRed">Ledenlijst</span>
    </h1>
    <v-layout
      row
      wrap
      class="ma-3"
      name="layoutMembersModule"
      v-if="users.length > 0"
    >
      <v-flex xs12 name="flexMembers">
        <v-flex class="ma-2">
          <v-card>
            <v-system-bar class="pa-4" color="#ebb63b">
              <span>
                <b>Ledenlijst</b>
              </span>
            </v-system-bar>
            <v-card-text>
              <template class="ma-4">
                <v-data-table
                  :headers="headers"
                  :items="users"
                  class="elevation-1"
                  hide-default-footer
                  loading-text="De ledenlijst wordt ingeladen"
                  disable-pagination
                ></v-data-table>
              </template>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-card class="ma-5" elevation="0" v-if="!users || users.length == 0">
      <v-row class="d-flex justify-space-around mb-6">
        <v-progress-circular
          class="ma-5 ahcRed"
          :size="70"
          :width="7"
          indeterminate
        ></v-progress-circular>
      </v-row>
      <v-row class="d-flex justify-space-around mb-6">
        <h3>Inladen ledenlijst...</h3>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Naam",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Email", value: "email" },
        { text: "Telnr.", value: "phoneNumber" },
        { text: "Adres", value: "adres" },
      ],
    };
  },
  computed: {
    users() {
      return this.$store.state.users;
    },
  },
  created() {
    this.$store.dispatch("loadUsers");
  },
};
</script>

<style>
</style>