<template>
  <v-container>
    <v-layout row wrap xs12 class="ma-2 justify-space-between">
      <h1>
        <span class="ahcRed">Carnavalspagadder</span>
      </h1>
    </v-layout>
    <v-card name="introWebsite">
      <v-system-bar class="pa-4" color="#ebb63b">
        <span>
          <b>Introductie tekst pagadder</b>
        </span>
      </v-system-bar>
      <v-textarea
        class="pa-2"
        v-model="pagadderIntro"
        disabled
        v-if="lockIntro"
      ></v-textarea>
      <v-textarea
        class="pa-2"
        v-model="newIntro"
        v-if="!lockIntro"
      ></v-textarea>
      <v-card-actions>
        <v-btn @click="changeIntro()" v-if="lockIntro">Aanpassen</v-btn>
        <v-btn v-if="!lockIntro" @click="updateIntro()">Opslaan</v-btn>
        <v-btn v-if="!lockIntro" @click="lockIntro = true">Annuleren</v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="my-5">
      <v-system-bar class="pa-4" color="#ebb63b">
        <span>
          <b>Binnengekomen suggesties</b>
        </span>
      </v-system-bar>
      <template class="ma-4">
        <v-data-table
          :headers="headers"
          :items="suggestions"
          class="elevation-0"
          hide-default-footer
          loading-text="Het jaarprogramma wordt ingeladen"
          disable-pagination
        >
        </v-data-table>
      </template>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      lockIntro: true,
      newIntro: null,
      headers: [
        {
          text: "Suggestie",
          align: "start",
          sortable: false,
          value: "suggestion",
        },
        {
          text: "Inzender",
          sortable: false,
          value: "name",
        },
        {
          text: "Email adres",
          sortable: false,
          value: "email",
        },
      ],
    };
  },
  computed: {
    userName() {
      return this.$store.state.currentUser.name;
    },
    pagadderIntro() {
      return this.$store.state.intros.pagadder;
    },
    suggestions() {
      return Object.values(this.$store.state.pagadderSuggestions);
    },
  },
  methods: {
    changeIntro() {
      this.lockIntro = false;
      this.newIntro = this.pagadderIntro;
    },
    updateIntro() {
      let intro = {
        name: "pagadder",
        text: this.newIntro,
      };
      this.$store.dispatch("updateIntros", intro);
      setTimeout(() => {
        this.lockIntro = true;
      }, 2000);
    },
  },
  created() {
    this.$store.dispatch("loadPagadderSuggestions");
  },
};
</script>

<style>
</style>